import GoogleMap from './ui-component';
import { connect } from 'react-redux';
import withSizes from 'react-sizes';
import { isSV, isMV, isLV } from 'utils/sizes';

export const DEFAULT_CENTER = { lat: 0, lng: 0 };

const mapStateToProps = (state, ownProps) => {
	const { mapDataAccr } = ownProps.redux; // 'businessSearchMap'

	return {
		API_KEY: state.config.apiKey,
		centerToMapMarker: ownProps.centerToMapMarker || state[mapDataAccr].centerToMapMarker,
		geoLocation: { lat: state.geoLocation.lat, lng: state.geoLocation.lng }
	};
};

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes),
	isMV: isMV(sizes),
	isLV: isLV(sizes)
});

export default withSizes(mapSizesToProps)(connect(mapStateToProps)(GoogleMap));
