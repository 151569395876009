import { asyncComponent } from 'hoc';

const LazyAdminDiningGuidesPage = asyncComponent(() => {
	return import(
		/* webpackChunkName: "AdminDiningGuidesPage" */
		/* webpackMode: "lazy" */
		'.'
	).then(module => module.default);
});

export default LazyAdminDiningGuidesPage;
