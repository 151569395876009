export const CLEAR_DINING_GUIDE = 'admin_dining_guides.CLEAR_DINING_GUIDE';
export const REMOVE_RESTAURANT_FOR_GUIDE = 'admin_dining_guides.REMOVE_RESTAURANT_FOR_GUIDE';
export const ADD_RESTAURANT_FOR_GUIDE = 'admin_dining_guides.ADD_RESTAURANT_FOR_GUIDE';
// export const SET_EDIT_SEARCH_QUERY = 'admin_dining_guides.SET_EDIT_SEARCH_QUERY';
export const SET_SEARCH_QUERY = 'admin_dining_guides.SET_SEARCH_QUERY';
export const UPDATE_TEMP_GUIDE = 'admin_dining_guides.UPDATE_TEMP_GUIDE';
export const CLEAR_DINING_GUIDE_EDITS = 'admin_dining_guides.CLEAR_DINING_GUIDE_EDITS';
export const CLEAR_TEMP_GUIDE_SELECTED_RESTAURANTS = 'admin_dining_guides.CLEAR_TEMP_GUIDE_SELECTED_RESTAURANTS';
export const CLEAR_EDIT_GUIDE_QUERY = 'admin_dining_guides.CLEAR_EDIT_GUIDE_QUERY';
export const SET_SELECT_FLOW_ACTIVE_TAB = 'admin_dining_guides.SET_SELECT_FLOW_ACTIVE_TAB';
