import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AreaSearchBtn, CompassIcon, SearchCenterMarker, UserLocationMapMarker } from 'components/atoms';
import { CenterMapBtn, ClusterMapMarker, GoogleMap, SimpleMapMarker } from 'components/molecules';
import { isEqual, round } from 'lodash';
import { hasProp } from 'utils/object';

class BusinessSearchMap extends Component {
	constructor(props) {
		super(props);

		this.searchThisArea = this.searchThisArea.bind(this);
		this.map = React.createRef();
	}

	setMapRef = ref => {
		this.map = ref;
	};

	transformResultToCluster = restaurant => {
		// Add a console.log statement to view the value of the 'restaurant' object
		// console.log(
		// 	'transformResultToCluster:',
		// 	restaurant,
		// 	'favorite:',
		// 	restaurant.user && restaurant.user.favorite === true
		// );

		return {
			type: 'Feature',
			properties: {
				name: restaurant.name,
				lat: restaurant.location.lat,
				lng: restaurant.location.lon,
				uid: restaurant.id,
				favorite: restaurant.user && restaurant.user.favorite === true ? true : false
			},
			geometry: {
				type: 'Point',
				coordinates: [restaurant.location.lon, restaurant.location.lat]
			}
		};
	};

	transformResultToSimpleMarker = (item, result) => {
		if (result) {
			return {
				activePin: this.props.activePin,
				mapCardDisplayed: this.props.mapCardDisplayed,
				id: item.properties.uid,
				name: item.properties.name,
				imgBanner: result.images.main,
				cuisine: result.cuisine.name,
				menuSpecialties: result.menuSpecialties,
				distance: result.distance,
				city: result.address.city,
				state: hasProp(result.address, 'state') ? result.address?.state?.abbreviation : '',
				bannerText: result.leaving ? 'Lorem.. Leaving Soon' : false,
				favorite: item.properties.favorite
			};
		}
	};

	clusterOptions = {
		minZoom: 1,
		maxZoom: 16,
		radius: 200
	};

	handleMarkerClick = (childProps, listView) => {
		this.props.setMapCardDisplayed(!listView);
	};

	handleMapClick = () => {
		this.props.setActivePin(null);
		this.props.setMapCardDisplayed(false);
	};

	handleMapChange = (args, mapInitialized) => {
		const { center, zoom } = args;
		// TODO may be problem on 0, 0
		if (this.props.center.lat !== 0 && this.props.center.lng !== 0 && center.lat === 0 && center.lng === 0) return;

		// the map has a little incorrect latlng that's why we need to round it
		let oldCenter = this.props.center
				? /* eslint-disable no-mixed-spaces-and-tabs */
				  {
						lat: round(this.props.center.lat, 4),
						lng: round(this.props.center.lng, 4)
				  }
				: null,
			/* eslint-enable no-mixed-spaces-and-tabs */
			newCenter = {
				lat: round(center.lat, 4),
				lng: round(center.lng, 4)
			},
			oldZoom = this.props.zoom;

		const hasNewCenter = !isEqual(oldCenter, newCenter),
			hasNewZoom = oldZoom !== zoom;

		if (mapInitialized && (hasNewCenter || hasNewZoom)) {
			this.props.setAreaSearchDisplayed(true);
			this.props.setZoom(zoom);
			this.props.setCenterPan(newCenter);
		}

		if (this.props.onMapChange) {
			this.props.onMapChange(args);
		}
	};

	centerMapButtonCallback = () => {
		this.props.reCenterToGeoLocation(true);
		this.props.setCenter(this.props.geoLocation);
	};

	setZoom = level => {
		this.props.setZoom(this.props.zoom + level);
	};

	searchThisArea = async () => {
		this.props.setAreaSearchDisplayed(false);
		let bounds = this.map.getBounds();

		let mapCenter = bounds.getCenter();
		let ne = bounds.getNorthEast();

		// r = radius of the earth in statute miles
		let r = 3963.0;

		// Convert lat or lng from decimal degrees into radians (divide by 57.2958)
		let lat1 = mapCenter.lat() / 57.2958;
		let lon1 = mapCenter.lng() / 57.2958;
		let lat2 = ne.lat() / 57.2958;
		let lon2 = ne.lng() / 57.2958;

		// distance = circle radius from center to Northeast corner of bounds
		let radius =
			r * Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1));

		await this.props.setCenter({ lat: mapCenter.lat(), lng: mapCenter.lng() });

		this.props.searchThisArea(radius);
	};

	getMapStyle() {
		const { isSV, size } = this.props;

		if (size) {
			return size;
		}

		const mapStyle = {
			height: isSV ? '90vh' : '100%',
			width: isSV ? '100vw' : '100%'
		};

		return mapStyle;
	}

	render() {
		const {
			activePin,
			center,
			centerPan,
			currentLocationSearch,
			geoLocation,
			hasGeoLocation,
			results,
			rehydrateFromSaveState
		} = this.props;

		const mapStyle = this.getMapStyle();
		const mapCenter = rehydrateFromSaveState ? centerPan : center;

		return (
			/*This id (business-search-map) is rendered to the DOM.  Needed so map markers can determine where to render card in relation to it's icon.*/
			<div className="map" style={mapStyle} id="business-search-map">
				{this.props.center.lat !== 0 && (
					<div className="map-header">
						{this.props.areaSearchDisplayed && <AreaSearchBtn onClick={this.searchThisArea} />}
						<div className="map-controls">
							{!this.props.geoLocationDenied && !this.props.hideGeolocationCenterButton && (
								<CompassIcon className="map-btn" onClick={currentLocationSearch} />
							)}
							{this.props.hasGeoLocation && (
								<CenterMapBtn centerMapButtonCallback={this.centerMapButtonCallback} />
							)}
							<div className="map-btn zoom-in" onClick={this.setZoom.bind(this, 1)} />
							<div className="map-btn zoom-out" onClick={this.setZoom.bind(this, -1)} />
						</div>
					</div>
				)}
				<GoogleMap
					centerToGeoLocation={this.props.centerToGeoLocation}
					reCenterToGeoLocation={this.props.reCenterToGeoLocation}
					mapRefSetter={this.setMapRef}
					id="business-search-map" // this ID is not actually rendered to the DOM. It is just passed to the map markers.
					activePin={activePin}
					center={mapCenter}
					clusterOptions={this.clusterOptions}
					clusterMarker={ClusterMapMarker}
					handleMarkerClick={this.handleMarkerClick}
					markerData={results}
					onMapClick={this.handleMapClick}
					onMapChange={this.handleMapChange}
					onZoom={this.props.setZoom}
					onCenter={this.props.setCenter}
					setActivePin={this.props.setActivePin}
					simpleMarker={SimpleMapMarker}
					simpleMarkerDataMap={this.transformResultToSimpleMarker}
					transformResultToCluster={this.transformResultToCluster}
					zoom={this.props.zoom}
					redux={this.props.redux}
					setRecenterToMapMarker={this.props.setRecenterToMapMarker}
				>
					{hasGeoLocation && geoLocation.lat && (
						<UserLocationMapMarker lat={geoLocation.lat} lng={geoLocation.lng} key="current-location" />
					)}

					<SearchCenterMarker lat={center.lat} lng={center.lng} key="search-center" />
				</GoogleMap>
			</div>
		);
	}
}

BusinessSearchMap.defaultProps = {
	results: []
};

const LATLNG = PropTypes.shape({
	lat: PropTypes.number,
	lng: PropTypes.number
});

BusinessSearchMap.propTypes = {
	activePin: PropTypes.string,
	areaSearchDisplayed: PropTypes.bool.isRequired,
	center: LATLNG.isRequired,
	centerPan: LATLNG.isRequired,
	centerToGeoLocation: PropTypes.bool, // used to control the trigger of reCenterToGeoLocation
	currentLocationSearch: PropTypes.func.isRequired,
	geoLocation: LATLNG,
	geoLocationDenied: PropTypes.bool.isRequired,
	hasGeoLocation: PropTypes.bool,
	isSV: PropTypes.bool.isRequired,
	mapCardDisplayed: PropTypes.bool,
	onMapChange: PropTypes.func,
	reCenterToGeoLocation: PropTypes.func, // used when centerToGeoLocation is true
	rehydrateFromSaveState: PropTypes.bool.isRequired,
	results: PropTypes.array,
	setActivePin: PropTypes.func.isRequired,
	setCenter: PropTypes.func.isRequired,
	setCenterPan: PropTypes.func.isRequired,
	searchThisArea: PropTypes.func.isRequired,
	setAreaSearchDisplayed: PropTypes.func.isRequired,
	setMapCardDisplayed: PropTypes.func.isRequired,
	setZoom: PropTypes.func.isRequired,
	zoom: PropTypes.number.isRequired,
	redux: PropTypes.shape({
		mapDataAccr: PropTypes.string.isRequired
	}).isRequired,
	setRecenterToMapMarker: PropTypes.func.isRequired,
	hideGeolocationCenterButton: PropTypes.bool,
	size: PropTypes.shape({
		height: PropTypes.string.isRequired,
		width: PropTypes.string.isRequired
	})
};

export default BusinessSearchMap;
