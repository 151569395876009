import { useCallback, useEffect, useState } from 'react';
import {
	geoLocationSuccess as geoLocationSuccessAction,
	geoLocationError as geoLocationErrorAction
} from 'actions/geo_location';
import { useDispatch } from 'react-redux';
import { setIsLoading as setIsLoadingAction } from 'actions/loader';
import { getLowerCasedParams } from 'utils/url';
import getMockGeolocation from 'test_utils/mockGeolocation';

const geoLocationFetchOptions = {
	// timeout: 15000, // 15s
	enableHighAccuracy: false,
	maximumAge: 1000 * 60 // 1m
};

const geoLocationErrors = {
	// user said no
	1: 'Please allow browser geolocation services or enter a search location.',
	// device can't get data
	2: 'There was an error trying to fetch your location.',
	// timeout
	3: 'Please allow browser geolocation services or enter a search location.'
};

const useUsersGeolocation = ({ getQuietly: getQuietlyProp } = {}) => {
	// If the URL contains location search params, we can get the user location in the background
	const { center, near, location } = getLowerCasedParams();
	const getQuietly = getQuietlyProp || center || (near && near !== 'Current Location') || location;

	// Legacy Redux
	const dispatch = useDispatch();
	const setIsLoading = useCallback((loading, message) => dispatch(setIsLoadingAction(loading, message)), [dispatch]);

	// Geolocation State
	const [navigatorId, setNavigatorId] = useState(false);
	const [geolocation, setGeolocation] = useState({});
	const [geolocationError, setGeolocationError] = useState();

	useEffect(() => {
		return () => {
			setIsLoading(false);
		};
	}, [setIsLoading]);

	const onLocationChange = useCallback(
		geolocation => {
			// Geolocation State
			setGeolocation(geolocation);
			// Legacy Redux
			geoLocationSuccessAction(geolocation, dispatch);
			if (!getQuietly) {
				setIsLoading(false);
			}
		},
		[dispatch, getQuietly, setIsLoading]
	);

	const onLocationError = useCallback(
		error => {
			// Geolocation State
			setGeolocationError(geoLocationErrors[error.code] || error.message);
			// Legacy Redux
			geoLocationErrorAction(error, dispatch);
			if (!getQuietly) {
				setIsLoading(false);
			}
		},
		[dispatch, getQuietly, setIsLoading]
	);

	useEffect(() => {
		// Legacy Redux
		if (!getQuietly) {
			setIsLoading(true, 'Getting Geolocation');
		}

		if (window.Cypress) {
			onLocationChange(getMockGeolocation());
		} else if (!navigatorId) {
			// Geolocation State
			const id = navigator.geolocation.watchPosition(onLocationChange, onLocationError, geoLocationFetchOptions);

			setNavigatorId(id);
		}
	}, [getQuietly, setIsLoading, onLocationChange, onLocationError, navigatorId]);

	useEffect(() => {
		// Geolocation State
		if (navigatorId && geolocation.lat) {
			navigator.geolocation.clearWatch(navigatorId);
		}
	}, [navigatorId, geolocation]);

	return {
		error: geolocationError,
		loading: !geolocationError && !geolocation.coords,
		loadGeolocation: () => {},
		locationServiceUnavailable: Boolean(geolocationError),
		position: geolocation
	};
};

export default useUsersGeolocation;
