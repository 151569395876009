/* eslint-disable no-case-declarations */
import * as t from '../actions/admin_dining_guides_search_map/types';

/**
 * Redux_RewardState
 * @interface Redux_RewardState
 * @property {array}  [availableRewards=[]]
 * @property {object} [selectedReward={}]
 * @property {object} [redeemedReward=undefined]
 */
const adminDiningSearchMapDefaultState = {
	areaSearchDisplayed: false,
	center: {
		lat: null,
		lng: null
	},
	centerPan: {
		lat: null,
		lng: null
	},
	centerToGeoLocation: false,
	centerToMapMarker: false,
	zoom: 10,
	rehydrateFromSaveState: false,
	hydrateState: null
};

export default (state = adminDiningSearchMapDefaultState, action) => {
	switch (action.type) {
		case t.CLEAR_ADMIN_DINING_SEARCH_MAP: {
			return adminDiningSearchMapDefaultState;
		}

		case t.SET_ADMIN_DINING_SEARCH_MAP_CENTER: {
			return {
				...state,
				center: action.payload
			};
		}

		case t.SET_ADMIN_DINING_SEARCH_MAP_ZOOM: {
			return {
				...state,
				zoom: action.payload
			};
		}

		case t.SET_ADMIN_DINING_AREA_SEARCH_BUTTON_DISPLAYED: {
			return {
				...state,
				areaSearchDisplayed: action.payload
			};
		}

		case t.RECENTER_ADMIN_DINING_TO_GEOLOCATION: {
			return {
				...state,
				centerToGeoLocation: action.payload
			};
		}

		case t.SET_ADMIN_DINING_RECENTER_TO_MAP_MARKER: {
			return {
				...state,
				centerToMapMarker: action.payload
			};
		}

		case t.SET_ADMIN_DINING_CENTER_PAN: {
			return {
				...state,
				centerPan: action.payload
			};
		}

		case t.SAVE_ADMIN_DINING_SEARCH_PAGE_STATE: {
			return {
				...state,
				rehydrateFromSaveState: true,
				hydrateState: {
					...state
				}
			};
		}

		case t.HYDRATE_ADMIN_DINING_FROM_SEARCH_PAGE_SAVE_STATE: {
			return {
				...state.hydrateState,
				center: state.hydrateState.centerPan,
				rehydrateFromSaveState: false,
				hydrateState: null
			};
		}

		default:
			return state;
	}
};
