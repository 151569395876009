import React from 'react';
import PropTypes from 'prop-types';
import { IconNavItem, NavItem } from 'components/atoms';
import { isNavItemActive } from 'utils/router';
import { WelcomePhrase } from 'components/atoms';
import MenuNavItem from './MenuNavItem';
import useGeneralContext from 'context/GeneralContext';

const RightIconNav = ({ config, isLV, menuItems, printIt }) => {
	const {
		authState: { permissions }
	} = useGeneralContext();

	let topNavItems = [],
		lowerNavItems = [];

	menuItems.map((item, index) => {
		let iconImg;
		if (item.useFaIcon) {
			iconImg = item.icon;
		} else if (item.icon) {
			iconImg = config.cdn_basePath + (isActive ? item.activeIcon : item.icon);
		}
		const isActive = isNavItemActive(item),
			hidden = !printIt(item);

		if (item.topNav) {
			topNavItems.push(
				<IconNavItem
					useFaIcon={item.useFaIcon}
					icon={iconImg}
					hidden={hidden}
					item={item}
					isActive={isActive}
					key={index}
				/>
			);
		} else {
			if (item.isMenu) {
				lowerNavItems.push(
					<MenuNavItem
						itemClassName="bold"
						hidden={hidden || !isLV}
						item={item}
						isActive={isActive}
						key={index}
						permissions={permissions}
					/>
				);
			} else {
				lowerNavItems.push(
					<NavItem
						itemClassName="bold"
						icon={iconImg}
						hidden={hidden}
						item={item}
						isActive={isActive}
						key={index}
					/>
				);
			}
		}
	});

	return (
		<div className="Di_HeaderIconMenu">
			<div className="flex justify-end mb-4">
				<WelcomePhrase />
				{topNavItems}
			</div>
			<div className="flex justify-end">{lowerNavItems}</div>
		</div>
	);
};

RightIconNav.defaultProps = {
	menuItems: []
};

RightIconNav.propTypes = {
	// Required
	config: PropTypes.object.isRequired,
	isLV: PropTypes.bool,
	printIt: PropTypes.func.isRequired,
	// Optional
	menuItems: PropTypes.array
};

export default RightIconNav;
