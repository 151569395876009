import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
	CheckboxGroup,
	CheckboxInput,
	FileInput,
	// DateInput,
	HelpButton,
	ToggleButton,
	Input,
	RadioInput,
	SelectInput
} from 'components/molecules';
// import './styles/main.scss';

/**
 * InputField - A common input component that can be configured to display any type of input.
 * @extends Component
 * @param {InputField_Props} props
 */
class InputField extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			hasFocus: props.hasFocus || false,
			isVisible: false,
			readOnly: props.readOnly || false
		};
	}

	toggleFocus = e => {
		let me = this,
			hasFocus = !this.state.hasFocus;

		if (!hasFocus && this.props.onBlur) {
			this.props.onBlur(e);
		} else if (hasFocus && this.props.onFocus) {
			this.props.onFocus(e);
		}

		if (!e.isPropagationStopped()) {
			me.setState({ hasFocus: e.type === 'focus' ? true : false });
		}

		if (this.props.toggle) {
			this.setState({ readOnly: !hasFocus });
		}
	};

	toggleVisibility = () => {
		this.setState({ isVisible: !this.state.isVisible });
	};

	inputs = {
		// date: DateInput,
		textarea: Input,
		text: Input,
		typeahead: Input,
		email: Input,
		file: FileInput,
		password: Input,
		radio: RadioInput,
		checkbox: CheckboxGroup,
		checkboxSingle: CheckboxInput,
		select: SelectInput
	};

	render() {
		const {
			alignOptions,
			blurValue,
			children,
			className,
			dataCy,
			disabled,
			error,
			example,
			fileType,
			hasError,
			hasRecentSearchData,
			hasRequirements,
			help,
			id,
			inputAlign,
			isReduxForm,
			label,
			maxLength,
			name,
			onChange,
			onErrorUsePlacehodler,
			onHelpClick,
			onKeyDown,
			onMouseOver,
			onMouseLeave,
			options,
			option,
			placeholder,
			purpose,
			recentSearches,
			refSetter,
			required,
			showHelp,
			toggle,
			triggers
		} = this.props;
		let type = this.props.type;
		const { hasFocus } = this.state;
		let value = this.props.value || this.state.value;
		if (type === 'checkbox') {
			value = this.state;
		}
		let autoCompleteParam = type === 'password' ? 'new-password' : 'off';
		if (toggle) {
			autoCompleteParam = 'off';
		}

		const InputComponent = this.inputs[type];

		let Children = children;
		let filteredList = [];
		if (type === 'typeahead' && typeof children === 'function') {
			filteredList = options.filter(
				o => o.value.toLowerCase().indexOf(value ? value.toLowerCase() : null) !== -1
			);

			if (filteredList.length > 0) {
				Children = filteredList.map(o => children(o));
			}
		}

		const classProps = classnames(
				'input-wrapper',
				disabled && 'disabled',
				hasError && 'error',
				help && 'with-help',
				toggle && 'with-toggle',
				triggers.length && 'with-help',
				required && 'required',
				type === 'typeahead' && children && filteredList.length > 0 && 'open',
				hasFocus && 'focused',
				className,
				hasRecentSearchData && hasRecentSearchData() && 'has-recent',
				type === 'typeahead' && this.state.hasFocus && Children && 'open',
				!this.state.isVisible && 'hide'
			),
			footerClassProps = classnames('input-footer', hasRequirements && 'has-requirements'),
			checkRadioSelectInputProps = options ? { options } : option ? { option } : {};

		return (
			<div className={classProps} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
				<InputComponent
					dataCy={dataCy}
					disabled={disabled}
					alignOptions={alignOptions} // radio, checkbox
					type={this.state.isVisible ? 'text' : type}
					hasError={hasError}
					isReduxForm={isReduxForm}
					error={error}
					fileType={fileType}
					label={label}
					id={id}
					inputAlign={inputAlign} // radio, checkbox
					maxLength={maxLength}
					name={id}
					{...checkRadioSelectInputProps}
					onBlur={this.toggleFocus}
					onChange={onChange}
					onErrorUsePlacehodler={onErrorUsePlacehodler}
					onFocus={this.toggleFocus}
					onKeyDown={onKeyDown}
					refSetter={refSetter}
					required={required}
					value={blurValue !== undefined && !hasFocus ? blurValue : value}
					className={className}
					placeholder={placeholder}
					purpose={purpose}
					autoComplete={autoCompleteParam}
					readOnly={this.state.readOnly}
				/>
				{triggers.length > 0 &&
					triggers.map(t => {
						return t;
					})}
				{toggle && (
					<ToggleButton
						id={`toggle-${id}-${name}-${label}`}
						onToggle={this.toggleVisibility}
						dataCy={dataCy}
						isOpen={this.state.isVisible}
					/>
				)}
				{help && (
					<HelpButton
						help={help}
						id={`help-${id}-${name}-${label}`}
						onHelpClick={onHelpClick}
						isOpen={showHelp}
						dataCy={dataCy}
					/>
				)}
				{example && (
					<span className="input-example" data-cy={`${dataCy}-example`}>
						{example}
					</span>
				)}
				<div className={footerClassProps}>
					{Children && Children}
					{hasFocus && recentSearches && recentSearches}
				</div>
			</div>
		);
	}
}

InputField.defaultProps = {
	alignOptions: null,
	children: null,
	error: null,
	example: null,
	hasError: false,
	help: null,
	toggle: false,
	id: null,
	inputAlign: null,
	label: null,
	name: 'input',
	required: false,
	triggers: [],
	type: 'text',
	onKeyDown: () => {},
	value: null
};

/**
 * InputField Props
 * @interface InputField_Props
 * @property {string}   [alignOptions=null]        How to align label and input - for radio and checkbox and checkboxgroup - can be "horizontal", "vertical", or null.
 * @property {node}     [children=null]            Additional components to show inside of the input field wrapper.  Ex: Input requirements.
 * @property {bool}     [disabled]                 If the field is disabled.
 * @property {string}   error                      The error message text.
 * @property {string}   example                    An example of how to fill out the input.
 * @property {string}   [fileType=null]            If this is a file input, the types of files that are accepted.
 * @property {boolean}  hasError                   If this input has an error.
 * @property {string}   help                       Text to show in the help CollapseWithClose.  If provided, will add a help trigger to the input.
 * @property {string}   id                         The id for this input.
 * @property {string}   isReduxForm                If this input is part of a redux form.
 * @property {string}   [inputAlign=null]          How to align options - for radio, checkbox, checkboxgroup. Can be "inline" or null.
 * @property {string}   label                      The label text for this input.
 * @property {string}   name                       The name for this input.
 * @property {funciton} onChange                   Handler for when this input's value changes.
 * @property {function} onKeyDown                  Handler for when this input is focused and a key is pressed.
 * @property {array}    options                    An array of data config objects for this inputs options - used for radio, checkboxgroup, select, typeahead.
 * @property {string}   placeholder                Input placeholder text.
 * @property {string}   purpose                    If this is a group of inputs, this will be what goes in the legend of the fieldset wrapping the inputs.
 * @property {boolean}  required                   If this is a required input.
 * @property {array}    triggers                   An array of trigger config objects.  Adds triggers to the right of this input.
 * @property {string}   [type='text']              The type of input.  Can be 'text', 'radio', 'checkbox', 'checkboxgroup', 'select', 'password', 'email', 'typeahead'.
 * @property {string}   value                      The current value of the input.
 */
InputField.propTypes = {
	// optional
	alignOptions: PropTypes.oneOf(['horizontal', 'vertical']),
	blurValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	children: PropTypes.node,
	className: PropTypes.string,
	dataCy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	disabled: PropTypes.bool,
	error: PropTypes.string,
	example: PropTypes.string,
	fileType: PropTypes.string,
	hasError: PropTypes.bool,
	hasFocus: PropTypes.bool,
	hasRecentSearchData: PropTypes.func,
	hasRequirements: PropTypes.bool,
	help: PropTypes.string,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	inputAlign: PropTypes.oneOf(['inline']), // for radio espcially, where we align label and options on same row.
	isReduxForm: PropTypes.bool,
	label: PropTypes.any,
	maxLength: PropTypes.number,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onErrorUsePlacehodler: PropTypes.bool,
	onFocus: PropTypes.func,
	onHelpClick: PropTypes.func,
	onKeyDown: PropTypes.func,
	onMouseOver: PropTypes.func,
	onMouseLeave: PropTypes.func,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			displayValue: PropTypes.string,
			id: PropTypes.string,
			name: PropTypes.string,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			checked: PropTypes.bool,
			disabled: PropTypes.bool
		})
	),
	option: PropTypes.shape({
		displayValue: PropTypes.string,
		id: PropTypes.string,
		name: PropTypes.string,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		checked: PropTypes.bool,
		disabled: PropTypes.bool
	}),
	placeholder: PropTypes.string,
	purpose: PropTypes.string,
	readOnly: PropTypes.bool,
	recentSearches: PropTypes.node,
	refSetter: PropTypes.func,
	required: PropTypes.bool,
	showHelp: PropTypes.bool,
	toggle: PropTypes.bool,
	triggers: PropTypes.array,
	type: PropTypes.oneOf([
		'checkbox',
		'checkboxSingle',
		'email',
		'file',
		'date',
		'password',
		'radio',
		'select',
		'text',
		'textarea',
		'typeahead'
	]),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.instanceOf(Date)])
};

export default InputField;
