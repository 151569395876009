import * as t from '../actions/location_autocomplete/types';
import * as geoLocationTypes from '../actions/geo_location/types';

/**
 * Redux_LocationAutocompleteState
 * @interface Redux_LocationAutocompleteState
 * @property {string}  [address='']
 * @property {boolean} [loading=false]
 * @property {array}   [suggestions=[]]
 * @property {boolean} [ready=false]
 */
const locationAutocompleteInstanceDefaultState = {
	currentLocationItem: false,
	activeItem: false,
	noSuggestionsFoundItem: false,
	search: '',
	showLoader: false,
	suggestions: [],
	canUseCurrentLocationSearch: true
};

const locationAutoCompleteReducerDefaultState = {};

const generateSuggestions = ({ state, suggestions, noResults }) => {
	let generatedSuggestions = [];
	let filteredSuggestions = [...suggestions];
	if (noResults) {
		generatedSuggestions.push(state.noSuggestionsFoundItem);
	} else {
		filteredSuggestions = filteredSuggestions.filter(
			s => s.description !== state.noSuggestionsFoundItem.description
		);
	}
	if (state.canUseCurrentLocationSearch) {
		generatedSuggestions.push(state.currentLocationItem);
	} else {
		filteredSuggestions = filteredSuggestions.filter(s => s.description !== state.currentLocationItem.description);
	}

	return [...generatedSuggestions, ...filteredSuggestions];
};

export default (state = locationAutoCompleteReducerDefaultState, action) => {
	switch (action.type) {
		case t.INIT_LOCATION_AUTOCOMPLETE_INSTANCE: {
			const { id, currentLocationItem, noSuggestionsFoundItem } = action.payload;
			const initialState = {
				...locationAutocompleteInstanceDefaultState,
				currentLocationItem,
				noSuggestionsFoundItem,
				suggestions: []
			};

			const generatedSuggestions = generateSuggestions({
				state: initialState,
				suggestions: initialState.suggestions,
				noResults: false
			});

			if (!state[id]) {
				return {
					...state,
					[id]: {
						...locationAutocompleteInstanceDefaultState,
						currentLocationItem,
						noSuggestionsFoundItem,
						suggestions: generatedSuggestions
					}
				};
			} else {
				return state;
			}
		}

		case t.FETCH_LOCATION_COMPLETIONS_START: {
			const { id, search, showLoader } = action.payload;

			return {
				...state,
				[id]: {
					...state[id],
					activeItem: false,
					search,
					showLoader
				}
			};
		}
		case t.FETCH_LOCATION_COMPLETIONS_SUCCESS: {
			const { id, suggestions } = action.payload;
			const generatedSuggestions = generateSuggestions({
				state: state[id],
				suggestions,
				noResults: suggestions.length === 0
			});

			return {
				...state,
				[id]: {
					...state[id],
					suggestions: generatedSuggestions,
					showLoader: false
				}
			};
		}
		case t.FETCH_LOCATION_COMPLETIONS_NO_RESULTS: {
			const id = action.payload;
			return {
				...state,
				[id]: {
					...state[id],
					suggestions: [state[id].noSuggestionsFoundItem, state[id].currentLocationItem],
					showLoader: false
				}
			};
		}

		case t.SET_LOCATION_AUTOCOMPLETE_ACTIVE_ITEM: {
			const { id, activeItem } = action.payload;

			return {
				...state,
				[id]: {
					...state[id],
					activeItem
				}
			};
		}

		case t.LOCATION_AUTOCOMPLETE_ITEM_CLICK: {
			const { suggestion } = action.payload;
			const id = action.payload.id;

			const generatedSuggestions = generateSuggestions({
				state: state[id],
				suggestions: [suggestion],
				noResults: false
			});

			return {
				...state,
				[id]: {
					...state[id],
					activeItem: 1,
					suggestions: generatedSuggestions
				}
			};
		}

		case geoLocationTypes.GEOLOCATION_SERVICES_PERMISSIONS_DENIED:
		case geoLocationTypes.FETCH_GEO_LOCATION_ERROR: {
			const newState = Object.entries(state).reduce((acc, [key, value]) => {
				const newSuggestions = generateSuggestions({
					state: {
						...value,
						canUseCurrentLocationSearch: false
					},
					suggestions: value.suggestions,
					noResults: false
				});
				return {
					...acc,
					[key]: {
						...value,
						canUseCurrentLocationSearch: false,
						suggestions: newSuggestions
					}
				};
			}, {});

			return {
				...state,
				...newState
			};
		}
		default:
			return state;
	}
};
