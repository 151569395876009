const filterRestaurants = (masterFilter, restaurants = [], newFilter) => {
	let filteredRestaurantIds = new Set();
	let filteredResults = [];
	let andFilters = {};
	let orFilters = {};
	Object.entries(masterFilter).forEach(([key, value]) => {
		if (value.filterType === 'AND') {
			andFilters[key] = value;
		} else {
			orFilters[key] = value;
		}
	});
	restaurants.forEach(r => {
		let passedAnd = true;
		Object.values(andFilters).forEach(filterGroup => {
			Object.values(filterGroup).forEach(filterItem => {
				if (passedAnd && filterItem.selected && filterItem.filterFunc) {
					passedAnd = filterItem.filterFunc(r, filterItem);
				}
			});
		});

		if (!passedAnd) {
			// failed and check
			return;
		} else {
			let passedOrGroups = [];
			Object.values(orFilters).forEach(filterGroup => {
				let passesGroup = [];
				Object.values(filterGroup).forEach(filterItem => {
					if (filterItem.filterFunc) {
						if (filterItem.selected) {
							passesGroup.push(filterItem.filterFunc(r, filterItem));
						}
					}
				});
				passedOrGroups.push(passesGroup.length === 0 || passesGroup.indexOf(true) > -1);
			});

			if (passedOrGroups.indexOf(false) === -1) {
				filteredRestaurantIds.add(r.id);
				filteredResults.push(r);
			}
			// else failed all "OR groups passed" checked
		}
	});

	if (newFilter.forEach) {
		newFilter.forEach(f => {
			updateCounts(masterFilter, filteredRestaurantIds, f);
		});
	} else {
		updateCounts(masterFilter, filteredRestaurantIds, newFilter);
	}

	return filteredResults;
};

const updateCounts = (masterFilter, filteredRestaurantIds, newFilter) => {
	let newFilterGroupName = newFilter.filterKey.split('.')[0];
	let newFilterType = masterFilter[newFilterGroupName].filterType;
	Object.entries(masterFilter).forEach(([filterGroupName, filterGroup]) => {
		if (filterGroupName === newFilterGroupName && newFilterType !== 'AND') {
			return;
		}
		if (newFilter.selected) {
			Object.values(filterGroup).forEach(filterItem => {
				if (filterItem.restaurantIds) {
					let filteredIds = new Set();
					filterItem.staticRestaurantIds.forEach(id => {
						if (filteredRestaurantIds.has(id)) {
							filteredIds.add(id);
						}
					});
					filterItem.restaurantIds = filteredIds;
				}
			});
		} else {
			Object.values(filterGroup).forEach(filterItem => {
				if (filterItem.restaurantIds) {
					let filteredIds = new Set();
					filterItem.staticRestaurantIds.forEach(id => {
						if (filteredRestaurantIds.has(id)) {
							filteredIds.add(id);
						}
					});
					filterItem.restaurantIds = filteredIds;
				}
			});
		}
	});
};
export default filterRestaurants;
