import { hasProp } from 'utils/object';

export let AutoCompleteService = null;

/**
 * Fetch google location object when nearby search triggered.
 * @param  {String} apiKey    Google API key.
 * @param  {String} [near=''] Near search param.
 */
export const getLocationFromGoogle = ({ near = '', location, placeId }) => {
	const google = window.google;
	const geocoder = new google.maps.Geocoder();
	if (placeId) {
		return geocoder.geocode({ placeId });
	}
	return location ? geocoder.geocode({ location }) : geocoder.geocode({ address: near });
};

export const getLatLngFromPlaceId = async ({ placeId }) => {
	const res = await getLocationFromGoogle({ placeId });
	const result = res.results[0];
	if (!result) {
		return {
			lat: null,
			lng: null
		};
	}

	const { lat, lng } = result.geometry.location;
	return {
		lat: lat(),
		lng: lng()
	};
};

// export const getLatLngFromGooglePlaceId = async ({ placeId }) => {
// 	const google = window.google;
// 	const places = new google.maps.places.PlacesService();
// 	return new Promise(resolve => {
// 		places.getDetails({ placeId }, (result, status) => {
// 			if (status === 'OK') {
// 				resolve(result.geometry.location);
// 			}
// 		});
// 	});
// 	// return res.results[0].geometry.location;
// };

export const initAutoCompleteService = () => {
	if (!AutoCompleteService) {
		if (!hasProp(window, 'google.maps.places.Autocomplete')) {
			throw new Error('Google Autocomplete Service is not loaded');
		} else {
			AutoCompleteService = new window.google.maps.places.AutocompleteService();
		}
	}
};

export const getSuggestionsFromGoogle = search => {
	initAutoCompleteService();
	return new Promise(resolve => {
		AutoCompleteService.getPlacePredictions({ input: search }, (results, status) => {
			if (status === 'OK') {
				resolve(results);
			} else {
				resolve(false);
			}
		});
	});
};

export const getLocationNameFromLatLng = async ({ lat, lng }) => {
	const res = await getLocationFromGoogle({ location: { lat, lng } });
	const place = res.results[0];
	const [, , city, , state] = place?.address_components;
	const name = `${city.long_name}, ${state.short_name}`;
	return name;
};
