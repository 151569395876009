import React, { createRef, useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Text } from 'components/atoms';
import { SearchPageQuickFilters } from 'components/organisms';
// import BusinessSearchFormSearchPage from 'components/organisms/BusinessSearchFormSearchPage';
import { useTranslate } from 'hooks';

const SearchTopBar = ({
	addFilter,
	removeFilter,
	children,
	className = '',
	isSV,
	onSearch,
	searchErrType,
	userFilters,
	redux,
	SearchForm
}) => {
	const translate = useTranslate();
	const innerWrapper = createRef();

	const renderLV = useCallback(() => {
		const classProps = classnames('search-top-bar', className);

		let errorMessage;
		if (searchErrType) {
			errorMessage = translate(searchErrType);
			if (errorMessage.indexOf('Missing') > -1) {
				errorMessage = searchErrType;
			}
		}

		return (
			<div className={classProps}>
				<div className="inner-wrapper" ref={innerWrapper}>
					<div className={classnames(!isSV && 'flex align-center')}>
						<Text className="mb-0 mr-4 no-wrap bold">{translate('Home.businessSearch.formLabel')}</Text>
						<SearchForm
							onSubmit={onSearch}
							submitButtonText={translate('common.searchCptl')}
							whiteBackground
							redux={redux}
						/>
					</div>
					{searchErrType && <div className="search-error-message">{errorMessage}</div>}
					{children && <div className="bottom-bar">{children}</div>}
				</div>
			</div>
		);
	}, [children, innerWrapper, className, isSV, onSearch, redux, searchErrType, translate]);

	const renderSV = useCallback(() => {
		const classProps = classnames('search-top-bar', className);

		let errorMessage;
		if (searchErrType) {
			errorMessage = translate(searchErrType);
			if (errorMessage.indexOf('Missing') > -1) {
				errorMessage = searchErrType;
			}
		}

		return (
			<div className={classProps}>
				<div className="inner-wrapper" ref={innerWrapper}>
					<SearchForm
						onSubmit={onSearch}
						submitButtonText={translate('common.searchCptl')}
						whiteBackground
						redux={redux}
					/>
					{searchErrType && <div className="search-error-message">{errorMessage}</div>}
					<SearchPageQuickFilters
						addFilter={addFilter}
						removeFilter={removeFilter}
						userFilters={userFilters}
					/>
					{children && <div className="bottom-bar">{children}</div>}
				</div>
			</div>
		);
	}, [
		addFilter,
		children,
		innerWrapper,
		removeFilter,
		redux,
		userFilters,
		className,
		onSearch,
		searchErrType,
		translate
	]);

	return isSV ? renderSV() : renderLV();
};

SearchTopBar.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	isSV: PropTypes.bool,
	onSearch: PropTypes.func.isRequired,
	searchErrType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	setupFlow: PropTypes.bool,
	userFilters: PropTypes.object.isRequired,
	redux: PropTypes.shape({
		searchAccr: PropTypes.string.isRequired,
		initialValues: PropTypes.shape({
			near: PropTypes.string,
			find: PropTypes.string
		})
	}).isRequired,
	SearchForm: PropTypes.elementType
};

export default SearchTopBar;
