import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

const MapMarker = ({ onClick, markerRef, id }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 49.7461 71.0243"
			width="30px"
			height="43px"
			onClick={onClick}
			ref={markerRef}
			id={`map-marker-${id}`}
			style={{ minWidth: 30 }}
		>
			<defs>
				<linearGradient
					id="a"
					x1="29.939"
					y1="797.0498"
					x2="40.414"
					y2="797.0498"
					gradientTransform="matrix(1, 0, 0, -1, 0, 822.1275)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stopColor="#c53844" />
					<stop offset="0.2271" stopColor="#c83c3f" />
					<stop offset="0.4887" stopColor="#d4482f" />
					<stop offset="0.7664" stopColor="#e75f24" />
					<stop offset="1" stopColor="#f47521" />
				</linearGradient>
				<linearGradient
					id="b"
					x1="26.5768"
					y1="787.6805"
					x2="37.261"
					y2="787.6805"
					gradientTransform="matrix(1, 0, 0, -1, 0, 822.1275)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stopColor="#f47521" />
					<stop offset="0.0938" stopColor="#f68a1f" />
					<stop offset="0.2054" stopColor="#f99b23" />
					<stop offset="0.3423" stopColor="#faa629" />
					<stop offset="0.5296" stopColor="#fbae2d" />
					<stop offset="1" stopColor="#fbaf2d" />
				</linearGradient>
				<linearGradient
					id="c"
					x1="14.5925"
					y1="786.8437"
					x2="23.1548"
					y2="786.8437"
					gradientTransform="matrix(1, 0, 0, -1, 0, 822.1275)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stopColor="#ee272c" />
					<stop offset="0.2166" stopColor="#ee2729" />
					<stop offset="0.3678" stopColor="#ed1c24" />
					<stop offset="0.4991" stopColor="#e21e26" />
					<stop offset="0.6191" stopColor="#ce2127" />
					<stop offset="0.7314" stopColor="#b01f24" />
					<stop offset="0.8379" stopColor="#8f1d1c" />
					<stop offset="0.938" stopColor="#661e0d" />
					<stop offset="1" stopColor="#47200f" />
				</linearGradient>
				<linearGradient
					id="d"
					x1="8.9064"
					y1="805.7547"
					x2="37.9272"
					y2="805.7547"
					gradientTransform="matrix(1, 0, 0, -1, 0, 822.1275)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stopColor="#12301a" />
					<stop offset="1" stopColor="#70bf44" />
				</linearGradient>
				<linearGradient id="e" x1="8.9111" y1="799.1791" x2="37.9231" y2="799.1791" href="#d" />
				<linearGradient id="f" x1="8.9082" y1="805.7728" x2="37.9236" y2="805.7728" href="#d" />
				<linearGradient id="g" x1="8.9106" y1="806.8076" x2="37.9211" y2="806.8076" href="#d" />
				<linearGradient id="h" x1="8.9063" y1="805.5822" x2="37.9257" y2="805.5822" href="#d" />
			</defs>
			<path
				className={styles.a}
				d="M42.5629,7.3829A24.87,24.87,0,0,0,7.3874,7.1907C-1.3555,15.84-2.5184,32.1876,4.8648,42.14L24.6379,71.0243,44.69,42.3873C52.209,32.4765,51.2123,16.1177,42.5629,7.3829Z"
			/>
			<path
				className={styles.b}
				d="M24.8579,2.6742C13.982,2.4568,8.3652,9.3313,8.3652,9.3313A27.0962,27.0962,0,0,0,2.2894,22.95a25.8965,25.8965,0,0,0,2.8284,15.818C6.6891,42.12,24.7943,67.3658,24.7943,67.3658S43.0218,42.12,44.5931,38.7675A25.8965,25.8965,0,0,0,47.4215,22.95C46.4787,14.9881,39.5561,2.968,24.8579,2.6742Z"
			/>
			<circle className={styles.a} cx="24.7089" cy="24.5324" r="15.7649" />
			<path
				className={styles.c}
				d="M29.939,21.5437c0,.6426.0149,1.2852.0149,1.9277.0149.8966.03,1.8081.03,2.7047,0,.6575,0,1.315-.03,1.9725v.03L37.5,33.7074a15.7656,15.7656,0,0,0,.6575-17.2592Z"
			/>
			<path
				className={styles.d}
				d="M29.9091,28.6865a7.3962,7.3962,0,0,1-.3138,1.4943,4.8513,4.8513,0,0,1-1.42,2.1219,8.099,8.099,0,0,1-1.39.9863.3228.3228,0,0,0-.1943.3287c0,.4334-.0149.8667-.0149,1.3,0,1.2552.0149,3.6012.0149,5.29A15.7488,15.7488,0,0,0,37.261,34.0511Z"
			/>
			<path
				className={styles.e}
				d="M23.11,40.2524c0-1.1207.0149-2.615.0149-3.2426V35.74c0-.7023.015-1.39.03-2.092v-.2989c-.09-.0448-.1494-.0747-.2241-.12-.2989-.1793-.6127-.3437-.9265-.523a4.6529,4.6529,0,0,1-1.6288-1.7334c-.1195-.2092-.2241-.4333-.3287-.6575l-5.4542,6.3956A15.8314,15.8314,0,0,0,23.11,40.2524Z"
			/>
			<path
				className={styles.f}
				d="M20.6145,9.5744l.1345,2.51.1345,2.4208.1345,2.4208c.0448.8218.09,1.6287.1345,2.4506l.1344,2.4208c.03.523.06,1.031.09,1.5391a.7243.7243,0,0,0,.015.1793.3721.3721,0,0,0,.5678.2391.434.434,0,0,0,.2391-.4184c.03-.8219.0747-1.6587.1046-2.48.06-1.2552.1046-2.5105.1643-3.7507.03-.792.0748-1.5691.1046-2.361.0449-.8219.0748-1.6587.1046-2.4806s.0748-1.6288.1046-2.4506c.015-.2989.03-.5828.0449-.8817a14.33,14.33,0,0,0-2.2564.4334A.4772.4772,0,0,1,20.6145,9.5744Z"
			/>
			<path
				className={styles.g}
				d="M19.434,22.7691c.03-.7621.0448-1.5242.09-2.2714.03-.9713.0747-1.9426.1046-2.9139.03-.9115.0747-1.823.1046-2.7345.03-.9713.0747-1.9426.1046-2.9139l.09-2.4208a15.7622,15.7622,0,0,0-5.6783,26.8676l5.6036-6.56a8.97,8.97,0,0,1-.3885-1.3748c-.06-.3287-.1494-2.69-.1494-2.9587C19.3742,24.5921,19.4041,23.6806,19.434,22.7691Z"
			/>
			<path
				className={styles.h}
				d="M26.6366,9.3353c.0149.6276.0448,1.2552.06,1.8828.03.9564.06,1.8978.09,2.8541.0149.7024.0448,1.42.0747,2.1219.03.6277.0448,1.27.0747,1.9128.03.7919.06,1.5989.09,2.3908.0149.538.03,1.0909.0448,1.6438v.1494h0s0,.1195.0149.254a1.2807,1.2807,0,0,0,.015.2391h0c.0149.269.0149.538.0149.5529a.511.511,0,0,0,.2989.4334.2559.2559,0,0,0,.2092,0,.5165.5165,0,0,0,.3138-.3437c.0149-.0747.0149-.1345.03-.2092.0149-.1495.06-.8817.06-1.0909.0149-.3586.0448-.7172.06-1.0609.0449-.7173.09-1.4346.1495-2.1518.0149-.3437.0448-.6874.06-1.0311.06-1.0161.12-2.0322.1943-3.0633.06-.8667.1046-1.7334.1644-2.6.06-.9563.1195-1.8978.1793-2.8541a18.5819,18.5819,0,0,0-2.2415-.4483A2.1007,2.1007,0,0,1,26.6366,9.3353Z"
			/>
			<path
				className={styles.i}
				d="M29.58,9.6043a.8484.8484,0,0,0,.015.1942c.0448,1.1058.09,2.1967.1345,3.3024.03.5978.0448,1.21.0747,1.8231.03.7173.0448,1.4345.0747,2.1518.0149.8368.0448,1.6587.06,2.48.0149.4931,0,.9863.0149,1.4794l7.9945-4.976A15.9411,15.9411,0,0,0,29.58,9.6043Z"
			/>
			<path
				className={styles.j}
				d="M23.4836,8.8571c.0149.4483.0448.8966.06,1.3449.0449.9115.09,1.838.1345,2.7495s.09,1.8231.1345,2.72l.1345,2.7794c.0448.9116.09,1.8231.1345,2.72.0448.8069.0747,1.6138.12,2.4207a1.051,1.051,0,0,0,.3138.6725.0823.0823,0,0,0,.0748.0448l.3436-.2092a.6824.6824,0,0,0,.1794-.4632c.06-1.1208.1345-2.2415.1942-3.3622.03-.4931.0449-.9862.0747-1.4794.0449-.777.1046-1.554.1495-2.3311.0149-.3586.0448-.7322.06-1.0908l.1345-2.4208c.06-1.0908.1345-2.1817.1943-3.2725.0149-.2839.03-.5678.0448-.8368-.4333-.03-.8816-.06-1.33-.06C24.2606,8.8123,23.8721,8.8272,23.4836,8.8571Z"
			/>
		</svg>
	);
};

MapMarker.propTypes = {
	markerRef: PropTypes.object,
	id: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired
};

export default MapMarker;
