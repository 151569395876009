import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/atoms';
import { startCase } from 'lodash';
import { hasProp } from 'utils/object';
import { useTranslate } from 'hooks';
import { ampli } from 'ampli';

const DiningOptionsContactInfo = ({ restaurant }) => {
	const translate = useTranslate();
	const { phone, url } = useMemo(() => {
		let url = false;
		if (hasProp(restaurant, 'amenities.onlineOrdering.url')) {
			url = restaurant.amenities.onlineOrdering.url;
		}

		return {
			phone: hasProp(restaurant, 'contacts.general.numbers.main')
				? restaurant.contacts.general.numbers.main
				: false,
			url: url
		};
	}, [restaurant]);
	let title = url
		? translate('Details.togo-options.or-call')
		: startCase(translate('Details.togo-options.or-call').replace('or ', ''));

	return (
		<div className={'dining-options-contact-info'}>
			{url && (
				<a
					className="btn btn-primary"
					href={url}
					target="_blank"
					rel="noopener noreferrer"
					onClick={() => ampli.restaurantOrderOnlineNowTapped()}
				>
					{translate('Details.togo-options.order-online')}
				</a>
			)}
			{phone && (
				<div className="flex justify-center align-center">
					<Text className="mr-2 mb-0">{title}</Text>
					<a href={`tel:${phone}`} onClick={() => ampli.restaurantCallTapped()}>
						{phone}
					</a>
				</div>
			)}
		</div>
	);
};

DiningOptionsContactInfo.propTypes = {
	restaurant: PropTypes.object.isRequired
};

export default DiningOptionsContactInfo;
