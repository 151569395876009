import LazyAnonDashboardPage from './AnonDashboardPage/lazy-container';
import LazyAdminDiningGuidesPage from './AdminDiningGuidesPage/lazy-container';
import LazyAutoEnrollAdminPage from './AutoEnrollAdminPage/lazy-container';
import LazyAutoEnrollUserPage from './AutoEnrollUserPage/lazy-container';
import LazyComingSoonBanner from 'components/organisms/ComingSoonBanner/lazy-container';
import LazyCompanyDashboard from './CompanyDashboard/lazy-container';
import LazyComingSoonPage from './ComingSoon/lazy-container.js';
import LazyDashboardPage from './DashboardPage/lazy-container.js';
import LazyDetailsAboutPage from './DetailsAboutPage/lazy-container.js';
import LazyDetailsHeader from './DetailsHeader/lazy-container.js';
import LazyDetailsPage from './DetailsPage/lazy-container.js';
import LazyDineAssistPage from './DineAssistPage/lazy-container.js';
import LazyDiningGuidesPage from './DiningGuidesPage/lazy-container.js';
import LazyDiningGuidePage from './DiningGuidePage/lazy-container.js';

// import LazyDineAssistFormPage from './DineAssistFormPage/lazy-container.js';
import LazyEditPaymentPage from './EditPaymentPage/lazy-container.js';
import LazyEditPersonalPage from './EditPersonalPage/lazy-container.js';
import LazyEditPreferencesPage from './EditPreferencesPage/lazy-container.js';
import LazyEditSecurity from './EditSecurityPage/lazy-container.js';
import LazyExpired from './Expired/lazy-container';
import LazyFavoritesPage from './Favorites/lazy-container.js';
import LazyFooter from './Footer/lazy-container.js';
import LazyForgottenPage from './ForgottenPage/lazy-container.js';
import LazyHeader from './Header/lazy-container.js';
import LazyLandingPage from './LandingPage/lazy-container.js';
import Layout from './Layout';
import LazyLoginPage from './Login/lazy-container.js';
import LazyLogoutPage from './LogoutPage/lazy-container.js';
import LazyModalLayout from './ModalLayout/lazy-container.js';
import LazyModalPageWrapper from './ModalPageWrapper/lazy-container.js';
import LazyNoResponsePage from './NoResponsePage/lazy-container';
import LazyNotFoundPage from './NotFoundPage/lazy-container';
import LazyOnboardingAdminPage from './OnboardingAdminPage/lazy-container';
import LazyOrderPage from './OrderPage/lazy-container.js';
import LazyOrderReceiptPage from './OrderReceiptPage/lazy-container.js';
import LazyPermissionsRoute from './PermissionsRoute/lazy-container.js';
import LazyPrivateRoute from './PrivateRoute/lazy-container.js';
import LazyProfilePage from './ProfilePage/lazy-container.js';
import LazyRecommendationsPage from './RecommendationsPage/lazy-container.js';
import LazyRedirectToAppStore from './RedirectToAppStore/lazy-container.js';
import LazyReferPage from './ReferPage/lazy-container.js';
import LazyRegistrationPage from './RegistrationPage/lazy-container.js';
import LazyResetPage from './Reset/lazy-container.js';
import LazyResetPasswordPage from './ResetPassword/lazy-container.js';
import LazyResetRoute from './ResetRoute/lazy-container.js';
import LazyRewardErrorPage from './RewardErrorPage/lazy-container.js';
import LazyRewardsPage from './RewardsPage/lazy-container.js';
import LazyRewardViewPage from './RewardViewPage/lazy-container.js';
import LazyRoster from './Roster/lazy-container.js';
import LazySearchPage from './SearchPage/lazy-container.js';
import LazySubscribePage from './SubscribePage/lazy-container.js';
// import LazySectionHeader from './SectionHeader/lazy-container.js';
import LazyTermsPage from './TermsPage/lazy-container.js';
import LazyTransactionsPage from './TransactionsPage/lazy-container.js';
import LazyUnsubscribePage from './UnsubscribePage/lazy-container.js';

export {
	LazyAnonDashboardPage,
	LazyAdminDiningGuidesPage,
	LazyAutoEnrollAdminPage,
	LazyAutoEnrollUserPage,
	LazyComingSoonBanner,
	LazyCompanyDashboard,
	LazyComingSoonPage,
	LazyDashboardPage,
	LazyDetailsAboutPage,
	LazyDetailsHeader,
	LazyDetailsPage,
	LazyDineAssistPage,
	LazyDiningGuidesPage,
	LazyDiningGuidePage,
	// LazyDineAssistFormPage,
	LazyEditPaymentPage,
	LazyEditPersonalPage,
	LazyEditPreferencesPage,
	LazyEditSecurity,
	LazyExpired,
	LazyFavoritesPage,
	LazyFooter,
	LazyForgottenPage,
	LazyHeader,
	LazyLandingPage,
	Layout,
	LazyLoginPage,
	LazyLogoutPage,
	LazyModalLayout,
	LazyModalPageWrapper,
	LazyNoResponsePage,
	LazyNotFoundPage,
	LazyOnboardingAdminPage,
	LazyOrderPage,
	LazyOrderReceiptPage,
	LazyPermissionsRoute,
	LazyPrivateRoute,
	LazyProfilePage,
	LazyRecommendationsPage,
	LazyRedirectToAppStore,
	LazyReferPage,
	LazyRegistrationPage,
	LazyResetPage,
	LazyResetPasswordPage,
	LazyResetRoute,
	LazyRewardErrorPage,
	LazyRewardViewPage,
	LazyRewardsPage,
	LazyRoster,
	LazySearchPage,
	LazySubscribePage,
	// LazySectionHeader,
	LazyTermsPage,
	LazyTransactionsPage,
	LazyUnsubscribePage
};
