import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecommendationOptionsQuery, useRestaurantSearchQuery } from 'reactQuery/queries';
import {
	buildRestaurantFilters,
	deselectFilter,
	filterRestaurants,
	selectFilter,
	updateFiltersFromURLParams,
	updateSavedFiltersArray
} from 'utils/search_new';
import { cloneDeep } from 'lodash';
import { useLocation } from 'react-router-dom';
import { applyURLParamFilters } from 'actions/new_business_search_filter';

/*
interface useRestaurantSearchProps {}
*/

const useRestaurantSearch = ({ searchParams = {}, onSearchSuccess } /*: useRestaurantSearchProps*/) => {
	const { data: recOptions } = useRecommendationOptionsQuery();

	const apiParams = useMemo(
		() => ({
			mock: searchParams.mock,
			radius: searchParams.radius,
			keywords: searchParams.keywords,
			lat: parseFloat(searchParams.lat),
			lng: parseFloat(searchParams.lng),
			uom: searchParams.uom
		}),
		[searchParams]
	);

	const { data: results, isFetched, isLoading, ...searchQueryOthers } = useRestaurantSearchQuery({
		searchParams: { ...apiParams },
		onSearchSuccess
	});

	const [staticFilters, setStaticFilters] = useState({});
	const [userFilters, setUserFilters] = useState({});
	const [selectedFilters, setSelectedFilters] = useState([]);
	const [filteredResults, setFilteredResults] = useState([]);
	const [didInitUrlFilters, setDidInitUrlFilters] = useState(false);

	const routerLocation = useLocation();

	useEffect(() => {
		// Init URL filters
		if (!didInitUrlFilters && Object.keys(userFilters).length > 0) {
			const params = applyURLParamFilters();
			let { newFilters: newUserFilters, selectedFilters: newSelectedFilters } = updateFiltersFromURLParams(
				params,
				cloneDeep(userFilters)
			);
			let _filteredResults = filterRestaurants(newUserFilters, results, newSelectedFilters);
			setFilteredResults(_filteredResults);

			setUserFilters(cloneDeep(newUserFilters));
			setSelectedFilters(cloneDeep(newSelectedFilters));
			setDidInitUrlFilters(true);
		}
	}, [didInitUrlFilters, routerLocation, results, selectedFilters, userFilters]);

	useEffect(() => {
		if (!results || !recOptions?.positive) {
			return;
		}
		const _filters = buildRestaurantFilters(results, recOptions?.positive);
		setStaticFilters(_filters);
		setUserFilters(_filters);
		setFilteredResults(results);
	}, [recOptions, results]);

	const addFilter = useCallback(
		filter => {
			const newUserFilters = selectFilter(filter, cloneDeep(userFilters));
			const newSelectedFilters = updateSavedFiltersArray(filter, cloneDeep(selectedFilters));
			const filteredResults = filterRestaurants(newUserFilters, results, filter);

			setUserFilters(newUserFilters);
			setSelectedFilters(newSelectedFilters);
			setFilteredResults(filteredResults);
		},
		[results, userFilters, selectedFilters]
	);

	const removeFilter = useCallback(
		filter => {
			const newUserFilters = deselectFilter(filter, cloneDeep(userFilters));
			const newSelectedFilters = updateSavedFiltersArray(filter, selectedFilters);
			const filteredResults = filterRestaurants(newUserFilters, results, filter);

			setUserFilters(newUserFilters);
			setSelectedFilters(newSelectedFilters);
			setFilteredResults(filteredResults);
		},
		[results, userFilters, selectedFilters]
	);

	const clearFilters = useCallback(() => {
		setUserFilters(cloneDeep(staticFilters));
		setSelectedFilters([]);
		setFilteredResults(results);
	}, [results, staticFilters]);

	return {
		addFilter,
		clearFilters,
		removeFilter,
		results,
		filteredResults,
		isLoading,
		isFetched,
		userFilters,
		selectedFilters,
		staticFilters,
		...searchQueryOthers
	};
};

export default useRestaurantSearch;
