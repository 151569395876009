import * as t from 'actions/admin_dining_guides_search_filter/types';
import * as s from 'actions/admin_dining_guides_edit_page/types';
import * as uiT from 'actions/admin_dining_guides_edit_page/types';
import {
	buildRestaurantFilters,
	deselectFilter,
	filterRestaurants,
	selectFilter,
	updateFiltersFromURLParams,
	updateSavedFiltersArray
} from 'utils/search_new';
import { cloneDeep } from 'lodash';

/**
 * Redux_RewardState
 * @interface Redux_RewardState
 * @property {array}  [filters=[]]
 */
const businessSearchFilter = {
	activeFilterCount: 0,
	// unchanging filters - set once after initial restaurant search.
	staticFilters: {},
	// changing filters - set once after initial restaurant search - update these when user filters, use these when rendering filtered list.
	userFilters: {},
	// if mobile view, when filter modal opens, set current filter state to tempFilters.  If user cancels from filter modal, use tempFilters to override userFilters.
	tempFilters: {},
	isLoading: false,
	selectedFiltersCount: 0,
	selectedFilters: [],
	tempSelectedFilters: 0,
	results: [],
	filteredResults: [],
	nearSearch: '',
	findSearch: '',
	didFetch: false,
	rehydrateFromSaveState: false,
	hydrateState: null,
	recOptions: []
};

export default (state = businessSearchFilter, action) => {
	switch (action.type) {
		case s.SEARCH_RESTAURANTS_START: {
			return {
				...state,
				isLoading: true
			};
		}
		case s.SEARCH_RESTAURANTS_SUCCESS: {
			let filters = buildRestaurantFilters(action.payload, state.recOptions);

			return {
				...state,
				isLoading: false,
				selectedFilters: [],
				selectedFiltersCount: 0,
				staticFilters: cloneDeep(filters),
				userFilters: filters,
				results: action.payload,
				filteredResults: action.payload,
				didFetch: true
			};
		}
		case s.SEARCH_RESTAURANTS_ERROR: {
			return {
				...state,
				isLoading: false
			};
		}
		case t.FILTER_WORKING: {
			return {
				...state,
				isLoading: action.payload
			};
		}
		case t.ADD_BUSINESS_FILTER: {
			const { filter } = action.payload,
				newFilters = selectFilter(filter, cloneDeep(state.userFilters)),
				selectedFilters = updateSavedFiltersArray(filter, state.selectedFilters),
				filteredResults = filterRestaurants(newFilters, state.results, filter);

			return {
				...state,
				activeFilterCount: state.activeFilterCount + 1,
				filteredResults,
				userFilters: newFilters,
				selectedFilters
			};
		}
		case t.REMOVE_BUSINESS_FILTER: {
			const { filter } = action.payload,
				newFilters = deselectFilter(filter, cloneDeep(state.userFilters)),
				selectedFilters = updateSavedFiltersArray(filter, state.selectedFilters),
				filteredResults = filterRestaurants(newFilters, state.results, filter);

			return {
				...state,
				activeFilterCount: state.activeFilterCount - 1,
				filteredResults,
				userFilters: newFilters,
				selectedFilters
			};
		}

		case t.APPLY_URL_SEARCH_FILTERS: {
			let urlParams = action.payload;
			let { newFilters, selectedFilters } = updateFiltersFromURLParams(urlParams, state.userFilters);
			let filteredResults = filterRestaurants(newFilters, state.results, selectedFilters);

			return {
				...state,
				activeFilterCount: selectedFilters.length,
				staticFilters: state.staticFilters,
				filteredResults,
				userFilters: newFilters,
				selectedFilters
			};
		}

		case t.CLEAR_FILTERS: {
			return {
				...state,
				activeFilterCount: 0,
				selectedFilters: [],
				userFilters: cloneDeep(state.staticFilters),
				filteredResults: state.results
			};
		}

		case uiT.TOGGLE_SHOW_FILTER: {
			let show = action.payload;
			if (show) {
				return {
					...state,
					tempSelectedFilters: state.selectedFiltersCount,
					tempFilters: state.userFilters
				};
			} else {
				return state;
			}
		}

		case t.SET_BUSINESS_SEARCH_FIND: {
			let { find } = action.payload;
			if (find === 'undefined') {
				find = '';
			}

			return {
				...state,
				findSearch: decodeURIComponent(find)
			};
		}

		case t.SET_BUSINESS_SEARCH_NEAR: {
			let { near } = action.payload;
			return {
				...state,
				nearSearch: decodeURIComponent(near)
			};
		}

		case t.SAVE_SEARCH_PAGE_STATE: {
			return {
				...state,
				rehydrateFromSaveState: true,
				hydrateState: {
					...state
				}
			};
		}

		case t.HYDRATE_FROM_SEARCH_PAGE_SAVE_STATE: {
			return {
				...state.hydrateState,
				rehydrateFromSaveState: false,
				hydrateState: null
			};
		}

		case t.RESET_SEARCH: {
			return {
				activeFilterCount: 0,
				staticFilters: {},
				userFilters: {},
				tempFilters: {},
				isLoading: true,
				selectedFiltersCount: 0,
				selectedFilters: [],
				tempSelectedFilters: 0,
				results: [],
				filteredResults: [],
				didFetch: false,
				rehydrateFromSaveState: false,
				hydrateState: null,
				recOptions: state.recOptions
			};
		}

		default:
			return state;
	}
};
