export const SEARCH_RESTAURANTS_START = 'admin_dining_guides_edit_page.SEARCH_RESTAURANTS_START';
export const SEARCH_RESTAURANTS_SUCCESS = 'admin_dining_guides_edit_page.SEARCH_RESTAURANTS_SUCCESS';
export const SEARCH_RESTAURANTS_ERROR = 'admin_dining_guides_edit_page.SEARCH_RESTAURANTS_ERROR';
export const TOGGLE_SHOW_FILTER = 'admin_dining_guides_edit_page.TOGGLE_SHOW_FILTER';
export const TOGGLE_SHOW_LIST = 'admin_dining_guides_edit_page.TOGGLE_SHOW_LIST';
export const SET_ACTIVE_PIN = 'admin_dining_guides_edit_page.SET_ACTIVE_PIN';
export const SET_MAP_CARD_DISPLAYED = 'admin_dining_guides_edit_page.SET_MAP_CARD_DISPLAYED';
export const SET_SEARCH_PAGE_ERROR_TYPE = 'admin_dining_guides_edit_page.SET_SEARCH_PAGE_ERROR_TYPE';
export const SAVE_SEARCH_PAGE_STATE = 'admin_dining_guides_edit_page.SAVE_SEARCH_PAGE_STATE';
export const HYDRATE_FROM_SEARCH_PAGE_SAVE_STATE = 'admin_dining_guides_edit_page.HYDRATE_FROM_SEARCH_PAGE_SAVE_STATE';
export const SET_LAST_BUSINESS_SEARCH = 'admin_dining_guides_edit_page.SET_LAST_BUSINESS_SEARCH';
