import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useUser } from 'hooks';
import { QueryClient } from '@tanstack/react-query';
import { USER_QUERY_KEY } from 'reactQuery/queries';
import useUsersGeolocation from 'hooks/useUsersGeolocation';

// interface useUserContextProps {}
const useUserContextValue = (/*:useUserContextProps*/) => {
	const userQuery = useUser();
	const { user } = userQuery;
	const [didClearUser, setDidClearUser] = useState(false);

	const geolocation = useUsersGeolocation({ getQuietly: true });

	useEffect(() => {
		setDidClearUser(false);
	}, [userQuery.isFetching]);

	const clearUser = useCallback(() => {
		const queryClient = new QueryClient();
		queryClient.removeQueries({ queryKey: USER_QUERY_KEY });
		setDidClearUser(true);
	}, []);

	return {
		clearUser,
		user: didClearUser ? {} : user,
		...userQuery,
		geolocation
	};
};

const UserContext = createContext({
	user: {},
	userQuery: {}
});

const UserContextProvider = ({ children }) => {
	const value = useUserContextValue();

	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserContextProvider.propTypes = {
	children: PropTypes.node
};

const useUserContext = () => {
	const context = useContext(UserContext);

	return context;
};

const UserContextConsumer = UserContext.Consumer;

export { UserContextConsumer, UserContextProvider };
export default useUserContext;
