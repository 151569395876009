import * as t from '../actions/admin_dining_guides/types';

// const mockSelectedRestaurants = new Set([
// 	{
// 		images: {
// 			logoImage: {
// 				url:
// 					'https://dinova-restaurants-dev.imgix.net/restaurants/brands/531d4f84-3c1e-5e31-80be-a7c960350f3c/images/logo/logo.jpg'
// 			},
// 			cuisineImage: {
// 				url:
// 					'https://dinova-restaurants-dev.imgix.net/restaurants/brands/e3b885a1-94fa-5fb2-82e3-d8096a28666f/images/main/Burger 21 Cuisine Cropped.png'
// 			},
// 			logo:
// 				'https://dinova-restaurants-dev.imgix.net/restaurants/brands/531d4f84-3c1e-5e31-80be-a7c960350f3c/images/logo/logo.jpg',
// 			main:
// 				'https://dinova-restaurants-dev.imgix.net/restaurants/brands/e3b885a1-94fa-5fb2-82e3-d8096a28666f/images/main/Burger 21 Cuisine Cropped.png'
// 		},
// 		address: {
// 			country: {
// 				iso: 'US'
// 			},
// 			city: 'Lawrenceville',
// 			street: ['630 Buford Highway'],
// 			postalCode: '30046',
// 			state: {
// 				abbreviation: 'GA'
// 			}
// 		},
// 		yelpScoresId: 'carrabbas-italian-grill-west-chester-3',
// 		specials: {
// 			offers: []
// 		},
// 		social: {
// 			twitter: 'https://twitter.com/Hardees',
// 			facebook: 'https://www.facebook.com/Hardees/',
// 			instagram: 'https://www.instagram.com/hardees/?hl=en'
// 		},
// 		cuisine: {
// 			name: 'Burger',
// 			id: 6
// 		},
// 		source: {
// 			name: 'Dinova',
// 			id: 0
// 		},
// 		networks: [
// 			{
// 				name: 'Mastercard',
// 				id: 1
// 			},
// 			{
// 				name: 'Chase',
// 				id: 2
// 			},
// 			{
// 				name: 'Citi',
// 				id: 3
// 			}
// 		],
// 		dutyOfCareUpdateDate: '2020-06-08T04:00:00.000Z',
// 		recommendations: {
// 			positive: {
// 				total: 5,
// 				categories: {
// 					'1': {
// 						total: 1
// 					},
// 					'2': {
// 						total: 1
// 					},
// 					'9': {
// 						total: 1
// 					},
// 					'30': {
// 						total: 3
// 					}
// 				}
// 			}
// 		},
// 		safetyMeasures: {},
// 		segment: {
// 			name: 'QSR',
// 			id: 6
// 		},
// 		lastUpdate: {
// 			date: '2024-01-27T03:31:54.745Z'
// 		},
// 		paymentMethods: {
// 			creditCards: [
// 				{
// 					name: 'AMEX',
// 					id: 1
// 				},
// 				{
// 					name: 'VISA',
// 					id: 2
// 				},
// 				{
// 					name: 'MC',
// 					id: 3
// 				}
// 			]
// 		},
// 		name: "Hardee's ",
// 		googlePlacesId: 'ChIJaaVFD0W-9YgRu2eJmfPityE',
// 		location: {
// 			lon: -83.986941,
// 			lat: 33.9756468
// 		},
// 		id: '5a885918-e31b-56c4-918e-7f5cbd5dc06a',
// 		brand: {
// 			name: 'Metro Deli/Bar/Cafe',
// 			id: '531d4f84-3c1e-5e31-80be-a7c960350f3c'
// 		},
// 		integrations: {
// 			zuppler: {
// 				id: null
// 			}
// 		},
// 		contacts: {
// 			general: {
// 				numbers: {
// 					main: '(770) 822-2444',
// 					fax: ''
// 				},
// 				email: '',
// 				url: 'https://www.hardees.com/'
// 			}
// 		},
// 		status: 1,
// 		menuSpecialties: [
// 			{
// 				name: 'Breakfast',
// 				id: 355
// 			},
// 			{
// 				name: 'Coffee',
// 				id: 362
// 			},
// 			{
// 				name: 'Desserts',
// 				id: 364
// 			}
// 		],
// 		distance: {
// 			span: '1.803616727507193',
// 			units: 'mi'
// 		},
// 		amenities: {
// 			delivery: {
// 				types: [
// 					{
// 						name: '3rd Party',
// 						id: 202
// 					}
// 				],
// 				url: 'http://www.doordash.com'
// 			},
// 			general: [
// 				{
// 					name: 'Drive thru',
// 					id: 5
// 				}
// 			],
// 			attire: [
// 				{
// 					name: 'Casual',
// 					id: 151
// 				}
// 			],
// 			entertainment: {
// 				alcohol: null,
// 				music: null,
// 				happyHour: null,
// 				seating: null
// 			},
// 			takeout: {
// 				url: 'https://www.hardees.com/locations'
// 			}
// 		}
// 	},
// 	{
// 		keywords: '',
// 		specials: {
// 			offers: []
// 		},
// 		description:
// 			'From oven-hot pizza to pasta, wings, and decadent desserts, Pizza Hut has got everything you crave. Call, click, or visit to place your order now. Because NO ONE OUTPIZZAS THE HUT.',
// 		source: {
// 			name: 'Dinova',
// 			id: 0
// 		},
// 		networks: [
// 			{
// 				name: 'Mastercard',
// 				id: 1
// 			},
// 			{
// 				name: 'Chase',
// 				id: 2
// 			},
// 			{
// 				name: 'Citi',
// 				id: 3
// 			}
// 		],
// 		otherSteps:
// 			'Our process requires that your pizza leaves our 400+ degree oven and slides hands free into the box so the only person who touches it after it comes out of the oven, is you.Tamper-proof safety seals are applied to all medium and large pizza boxes, as well as Dinner Box, Big Dinner Box and Big Dipper orders.All guest-facing restaurant team members, including delivery drivers, are advised to wear disposable gloves during their shifts. Gloves do not replace our industry-leading handwashing and sanitation standards that exceed CDC guidelines.Temperature check procedures will be implemented as thermometers become available.Clear protective shields will be available for restaurants to install at the front counter for an extra layer of protection for contactless carryout orders.Social distancing signage is available to be posted for both team members and customers.',
// 		recommendations: {
// 			positive: {
// 				total: 6,
// 				categories: {
// 					'1': {
// 						total: 1
// 					},
// 					'2': {
// 						total: 2
// 					},
// 					'3': {
// 						total: 1
// 					},
// 					'12': {
// 						total: 2
// 					},
// 					'31': {
// 						total: 1
// 					}
// 				}
// 			}
// 		},
// 		safetyMeasures: {},
// 		segment: {
// 			name: 'QSR',
// 			id: 6
// 		},
// 		googlePlacesId: 'ChIJpyB_2YmV9YgRIpCSHmU0lrE',
// 		id: '09a53022-fd09-5db4-893a-642b19fe744c',
// 		dietaryPreferences: [
// 			{
// 				name: 'Gluten-free',
// 				id: 2
// 			},
// 			{
// 				name: 'Vegan/Vegetarian',
// 				id: 11
// 			}
// 		],
// 		brand: {
// 			name: 'Pizza Hut',
// 			id: '9e0d430b-b219-56b7-90a4-888e9f0410b8'
// 		},
// 		menuSpecialties: [
// 			{
// 				name: 'Desserts',
// 				id: 364
// 			},
// 			{
// 				name: 'Pasta',
// 				id: 372
// 			},
// 			{
// 				name: 'Pizza',
// 				id: 374
// 			},
// 			{
// 				name: 'Wings',
// 				id: 392
// 			}
// 		],
// 		images: {
// 			logoImage: {
// 				altText: 'Pizza Hut Logo',
// 				url:
// 					'https://dinova-restaurants-dev.imgix.net/restaurants/brands/9e0d430b-b219-56b7-90a4-888e9f0410b8/images/logo/logo.jpg'
// 			},
// 			cuisineImage: {
// 				url:
// 					'https://dinova-restaurants-dev.imgix.net/restaurants/brands/c229e1d0-beef-523f-a1de-0437cf8a1f79/images/main/Fresh-Mozz-and-Tomato-Pizza.jpg'
// 			},
// 			logo:
// 				'https://dinova-restaurants-dev.imgix.net/restaurants/brands/9e0d430b-b219-56b7-90a4-888e9f0410b8/images/logo/logo.jpg',
// 			main:
// 				'https://dinova-restaurants-dev.imgix.net/restaurants/brands/c229e1d0-beef-523f-a1de-0437cf8a1f79/images/main/Fresh-Mozz-and-Tomato-Pizza.jpg'
// 		},
// 		address: {
// 			country: {
// 				iso: 'US'
// 			},
// 			city: 'Lawrenceville',
// 			street: ['1475 Highway 20', 'Suite 201'],
// 			postalCode: '30043',
// 			state: {
// 				abbreviation: 'GA'
// 			}
// 		},
// 		social: {
// 			twitter: 'https://twitter.com/pizzahut',
// 			facebook: 'https://www.facebook.com/PizzaHut'
// 		},
// 		cuisine: {
// 			name: 'Pizza',
// 			id: 15
// 		},
// 		dutyOfCareUpdateDate: '2020-06-23T04:00:00.000Z',
// 		lastUpdate: {
// 			date: '2024-02-01T15:57:24.612Z'
// 		},
// 		name: 'Pizza Hut ',
// 		location: {
// 			lon: -83.9862778,
// 			lat: 34.0056514
// 		},
// 		integrations: {
// 			zuppler: {
// 				id: null
// 			}
// 		},
// 		contacts: {
// 			general: {
// 				numbers: {
// 					main: '(770) 962-7575',
// 					fax: ''
// 				},
// 				email: '',
// 				url: 'http://www.pizzahut.com'
// 			}
// 		},
// 		status: 1,
// 		distance: {
// 			span: '1.3090675234284745',
// 			units: 'mi'
// 		},
// 		amenities: {
// 			delivery: {
// 				types: [
// 					{
// 						name: 'Self',
// 						id: 201
// 					}
// 				],
// 				url: 'https://www.pizzahut.com/'
// 			},
// 			general: [
// 				{
// 					name: 'Curbside',
// 					id: 2
// 				}
// 			],
// 			entertainment: {
// 				alcohol: null,
// 				music: null,
// 				happyHour: null,
// 				seating: null
// 			},
// 			onlineOrdering: {
// 				url: 'https://www.pizzahut.com'
// 			}
// 		}
// 	},
// 	{
// 		specials: {
// 			offers: []
// 		},
// 		source: {
// 			name: 'Dinova',
// 			id: 0
// 		},
// 		networks: [
// 			{
// 				name: 'Mastercard',
// 				id: 1
// 			},
// 			{
// 				name: 'Chase',
// 				id: 2
// 			},
// 			{
// 				name: 'Citi',
// 				id: 3
// 			}
// 		],
// 		otherSteps: 'Employees temperature checks and health declaration form required',
// 		recommendations: {
// 			positive: {
// 				total: 0,
// 				categories: {}
// 			}
// 		},
// 		safetyMeasures: {},
// 		segment: {
// 			name: 'Cafe/Bistro/Deli/Bakery',
// 			id: 5
// 		},
// 		paymentMethods: {
// 			creditCards: [
// 				{
// 					name: 'AMEX',
// 					id: 1
// 				},
// 				{
// 					name: 'VISA',
// 					id: 2
// 				},
// 				{
// 					name: 'MC',
// 					id: 3
// 				},
// 				{
// 					name: 'DISCOVER',
// 					id: 6
// 				}
// 			]
// 		},
// 		googlePlacesId: 'ChIJ_3cCutO99YgR6ZEzXMNsAqQ',
// 		id: '22c8a11b-ec1b-5bcb-94ac-3b6093aa279a',
// 		dietaryPreferences: [
// 			{
// 				name: 'Vegan/Vegetarian',
// 				id: 11
// 			}
// 		],
// 		brand: {
// 			name: 'Vault 164',
// 			id: '25dff2c5-70c8-5776-90cd-895d18c152aa'
// 		},
// 		menuSpecialties: [
// 			{
// 				name: 'Breakfast',
// 				id: 355
// 			},
// 			{
// 				name: 'Coffee',
// 				id: 362
// 			},
// 			{
// 				name: 'Sandwiches',
// 				id: 378
// 			},
// 			{
// 				name: 'Smoothies',
// 				id: 381
// 			}
// 		],
// 		images: {
// 			logoImage: {
// 				url:
// 					'https://dinova-restaurants-dev.imgix.net/restaurants/brands/25dff2c5-70c8-5776-90cd-895d18c152aa/images/logo/logo.png'
// 			},
// 			cuisineImage: {
// 				url:
// 					'https://dinova-restaurants-dev.imgix.net/restaurants/brands/25dff2c5-70c8-5776-90cd-895d18c152aa/images/main/PrairieFarmsPorkChop.jpg'
// 			},
// 			logo:
// 				'https://dinova-restaurants-dev.imgix.net/restaurants/brands/25dff2c5-70c8-5776-90cd-895d18c152aa/images/logo/logo.png',
// 			main:
// 				'https://dinova-restaurants-dev.imgix.net/restaurants/brands/25dff2c5-70c8-5776-90cd-895d18c152aa/images/main/PrairieFarmsPorkChop.jpg'
// 		},
// 		address: {
// 			country: {
// 				iso: 'US'
// 			},
// 			city: 'Lawrenceville',
// 			street: ['900 Duluth Highway', 'Suite 500'],
// 			postalCode: '30043',
// 			state: {
// 				abbreviation: 'GA'
// 			}
// 		},
// 		social: {
// 			twitter: 'https://twitter.com/EinsteinBros',
// 			facebook: 'https://www.facebook.com/einsteinbros/',
// 			instagram: 'https://www.instagram.com/einsteinbros/?hl=en'
// 		},
// 		cuisine: {
// 			name: 'Breakfast',
// 			id: 5
// 		},
// 		dutyOfCareUpdateDate: '2020-06-23T04:00:00.000Z',
// 		lastUpdate: {
// 			date: '2023-08-23T15:36:36.664Z'
// 		},
// 		name: 'Einstein Bros. Bagels ',
// 		location: {
// 			lon: -84.0238559,
// 			lat: 33.9674025
// 		},
// 		integrations: {
// 			zuppler: {
// 				id: null
// 			}
// 		},
// 		contacts: {
// 			general: {
// 				numbers: {
// 					main: '(770) 822-1039',
// 					fax: ''
// 				},
// 				email: '',
// 				url: 'https://www.einsteinbros.com/'
// 			}
// 		},
// 		status: 1,
// 		distance: {
// 			span: '2.240255912188692',
// 			units: 'mi'
// 		},
// 		amenities: {
// 			delivery: {
// 				types: [
// 					{
// 						name: '3rd Party',
// 						id: 202
// 					}
// 				],
// 				url: 'https://www.doordash.com/business/einstein-bros-bagels-341674/'
// 			},
// 			general: [
// 				{
// 					name: 'Curbside',
// 					id: 2
// 				},
// 				{
// 					name: 'Meal Kits',
// 					id: 7
// 				}
// 			],
// 			attire: [
// 				{
// 					name: 'Casual',
// 					id: 151
// 				}
// 			],
// 			entertainment: {
// 				alcohol: null,
// 				music: null,
// 				happyHour: null,
// 				seating: null
// 			},
// 			catering: {
// 				types: [
// 					{
// 						name: 'Self-Service',
// 						id: 1
// 					}
// 				],
// 				url: 'https://ebcatering.com'
// 			},
// 			takeout: {
// 				url: 'https://ebcatering.com'
// 			},
// 			menu: [
// 				{
// 					name: 'Digital Menu',
// 					id: 301
// 				}
// 			]
// 		}
// 	},
// 	{
// 		specials: {
// 			offers: []
// 		},
// 		description:
// 			'Jersey Mike’s, a fast-casual sub sandwich franchise with more than 1,500 stores open and under development nationwide, has a long history of community involvement and support.  Started at the Jersey Shore in 1956, Jersey Mike’s serves authentic East Coast-style subs on fresh baked bread – the same recipe it started with over 60 years ago.  The company’s mission is to bring its customers the highest quality, freshest made sub in the industry and give back to the communities in which it operates. A store locator and franchise information for Jersey Mike’s can be found at www.jerseymikes.com.',
// 		source: {
// 			name: 'Dinova',
// 			id: 0
// 		},
// 		networks: [
// 			{
// 				name: 'Mastercard',
// 				id: 1
// 			},
// 			{
// 				name: 'Chase',
// 				id: 2
// 			},
// 			{
// 				name: 'Citi',
// 				id: 3
// 			}
// 		],
// 		recommendations: {
// 			positive: {
// 				total: 0,
// 				categories: {}
// 			}
// 		},
// 		safetyMeasures: {},
// 		segment: {
// 			name: 'Casual',
// 			id: 3
// 		},
// 		paymentMethods: {
// 			creditCards: [
// 				{
// 					name: 'AMEX',
// 					id: 1
// 				},
// 				{
// 					name: 'VISA',
// 					id: 2
// 				},
// 				{
// 					name: 'MC',
// 					id: 3
// 				}
// 			]
// 		},
// 		googlePlacesId: 'ChIJQ-FWOaS99YgRyn-kO5X3zFY',
// 		id: '044aca4b-1193-5773-bfa5-21bdf55b4741',
// 		dietaryPreferences: [
// 			{
// 				name: 'Healthy',
// 				id: 4
// 			}
// 		],
// 		brand: {
// 			name: 'MarketBar',
// 			id: '41537a7f-ec87-5da0-874d-465b376d07ee'
// 		},
// 		menuSpecialties: [
// 			{
// 				name: 'Salads',
// 				id: 377
// 			},
// 			{
// 				name: 'Sandwiches',
// 				id: 378
// 			}
// 		],
// 		images: {
// 			logoImage: {
// 				url:
// 					'https://dinova-restaurants-dev.imgix.net/restaurants/brands/41537a7f-ec87-5da0-874d-465b376d07ee/images/logo/logo.jpg'
// 			},
// 			cuisineImage: {
// 				url:
// 					'https://dinova-restaurants-dev.imgix.net/restaurants/brands/9355f1f9-e68c-54b1-8e01-2a2e3e7facdb/images/main/bacon-egg-cheese-tomato-bagel-sandwich.jpg'
// 			},
// 			logo:
// 				'https://dinova-restaurants-dev.imgix.net/restaurants/brands/41537a7f-ec87-5da0-874d-465b376d07ee/images/logo/logo.jpg',
// 			main:
// 				'https://dinova-restaurants-dev.imgix.net/restaurants/brands/41537a7f-ec87-5da0-874d-465b376d07ee/images/main/Marketbar.jpg'
// 		},
// 		address: {
// 			country: {
// 				iso: 'US'
// 			},
// 			city: 'Lawrenceville',
// 			street: ['938 Duluth Highway', 'Suite C-1'],
// 			postalCode: '30043',
// 			state: {
// 				abbreviation: 'GA'
// 			}
// 		},
// 		social: {
// 			twitter: 'https://twitter.com/jerseymikes',
// 			facebook: 'https://www.facebook.com/jerseymikes/',
// 			instagram: 'https://www.instagram.com/jerseymikes/'
// 		},
// 		cuisine: {
// 			name: 'Sandwich',
// 			id: 17
// 		},
// 		dutyOfCareUpdateDate: '2020-07-15T04:00:00.000Z',
// 		lastUpdate: {
// 			date: '2023-08-23T15:36:36.664Z'
// 		},
// 		name: "Jersey Mike's ",
// 		location: {
// 			lon: -84.026003,
// 			lat: 33.967542
// 		},
// 		integrations: {
// 			zuppler: {
// 				id: null
// 			}
// 		},
// 		contacts: {
// 			general: {
// 				numbers: {
// 					main: '(678) 737-1888',
// 					fax: ''
// 				},
// 				email: '',
// 				url: 'https://www.jerseymikes.com/'
// 			}
// 		},
// 		status: 1,
// 		distance: {
// 			span: '2.290154206435683',
// 			units: 'mi'
// 		},
// 		amenities: {
// 			delivery: {
// 				types: [
// 					{
// 						name: '3rd Party',
// 						id: 202
// 					}
// 				],
// 				url: 'https://www.jerseymikes.com/order'
// 			},
// 			attire: [
// 				{
// 					name: 'Casual',
// 					id: 151
// 				}
// 			],
// 			entertainment: {
// 				alcohol: null,
// 				music: null,
// 				happyHour: null,
// 				seating: null
// 			},
// 			onlineOrdering: {
// 				url: 'https://www.jerseymikes.com/order'
// 			},
// 			catering: {
// 				types: [
// 					{
// 						name: 'Self-Service',
// 						id: 1
// 					}
// 				],
// 				url: 'https://www.jerseymikes.com/menu/catering'
// 			},
// 			takeout: {
// 				url: 'https://www.jerseymikes.com/'
// 			},
// 			menu: [
// 				{
// 					name: 'Digital Menu',
// 					id: 301
// 				}
// 			]
// 		}
// 	},
// 	{
// 		images: {
// 			logoImage: {
// 				url:
// 					'https://dinova-restaurants-dev.imgix.net/restaurants/brands/1f18c117-52c5-5abc-b1be-90fd2eeb5fb3/images/logo/logo.png'
// 			},
// 			cuisineImage: {
// 				altText: ' stack with attachment',
// 				url:
// 					'https://dinova-restaurants-dev.imgix.net/restaurants/brands/c253f3d1-ca32-58ec-9a49-69b469cab7d5/images/main/Bob Evans cuisine image_official.jpg'
// 			},
// 			logo:
// 				'https://dinova-restaurants-dev.imgix.net/restaurants/brands/1f18c117-52c5-5abc-b1be-90fd2eeb5fb3/images/logo/logo.png',
// 			main:
// 				'https://dinova-restaurants-dev.imgix.net/restaurants/brands/1f18c117-52c5-5abc-b1be-90fd2eeb5fb3/images/main/5.jpg'
// 		},
// 		address: {
// 			country: {
// 				iso: 'US'
// 			},
// 			city: 'Lawrenceville',
// 			street: ['860 Duluth Highway'],
// 			postalCode: '30043',
// 			state: {
// 				abbreviation: 'GA'
// 			}
// 		},
// 		specials: {
// 			offers: []
// 		},
// 		social: {
// 			twitter: 'https://twitter.com/hooters',
// 			facebook: 'https://www.facebook.com/hooters/',
// 			instagram: 'https://www.instagram.com/hooters/'
// 		},
// 		cuisine: {
// 			name: 'American',
// 			id: 1
// 		},
// 		source: {
// 			name: 'Dinova',
// 			id: 0
// 		},
// 		networks: [
// 			{
// 				name: 'Mastercard',
// 				id: 1
// 			},
// 			{
// 				name: 'Chase',
// 				id: 2
// 			},
// 			{
// 				name: 'Citi',
// 				id: 3
// 			}
// 		],
// 		recommendations: {
// 			positive: {
// 				total: 0,
// 				categories: {}
// 			}
// 		},
// 		safetyMeasures: {},
// 		segment: {
// 			name: 'Casual',
// 			id: 3
// 		},
// 		lastUpdate: {
// 			date: '2023-08-23T15:36:36.664Z'
// 		},
// 		paymentMethods: {
// 			creditCards: [
// 				{
// 					name: 'AMEX',
// 					id: 1
// 				},
// 				{
// 					name: 'VISA',
// 					id: 2
// 				},
// 				{
// 					name: 'MC',
// 					id: 3
// 				}
// 			]
// 		},
// 		name: 'Hooters ',
// 		location: {
// 			lon: -84.0232923,
// 			lat: 33.9665137
// 		},
// 		id: 'f55468a3-9eb0-5b0b-8881-02cfee2137ce',
// 		brand: {
// 			name: 'Range',
// 			id: '1f18c117-52c5-5abc-b1be-90fd2eeb5fb3'
// 		},
// 		integrations: {
// 			zuppler: {
// 				id: null
// 			}
// 		},
// 		contacts: {
// 			general: {
// 				numbers: {
// 					main: '770-513-7260',
// 					fax: ''
// 				},
// 				email: '',
// 				url: 'https://www.hooters.com/'
// 			}
// 		},
// 		status: 1,
// 		menuSpecialties: [
// 			{
// 				name: 'Salads',
// 				id: 377
// 			},
// 			{
// 				name: 'Wings',
// 				id: 392
// 			}
// 		],
// 		distance: {
// 			span: '2.2811163104953223',
// 			units: 'mi'
// 		},
// 		amenities: {
// 			general: [
// 				{
// 					name: 'Dine-in',
// 					id: 1
// 				},
// 				{
// 					name: 'Curbside',
// 					id: 2
// 				}
// 			],
// 			attire: [
// 				{
// 					name: 'Casual',
// 					id: 151
// 				}
// 			],
// 			entertainment: {
// 				alcohol: [
// 					{
// 						name: 'Full-bar',
// 						id: 101
// 					}
// 				],
// 				music: null,
// 				happyHour: null,
// 				seating: null
// 			},
// 			onlineOrdering: {
// 				url: 'https://www.hooters.com/locations/'
// 			},
// 			takeout: {
// 				url: 'https://www.hooters.com/locations/'
// 			}
// 		}
// 	},
// 	{
// 		keywords: '',
// 		specials: {
// 			offers: []
// 		},
// 		description:
// 			'Panda Express cooks with the bold flavors of Chinese cuisine in mind, featuring their famous orange chicken and chow mein.',
// 		source: {
// 			name: 'Dinova',
// 			id: 0
// 		},
// 		networks: [
// 			{
// 				name: 'Mastercard',
// 				id: 1
// 			},
// 			{
// 				name: 'Chase',
// 				id: 2
// 			},
// 			{
// 				name: 'Citi',
// 				id: 3
// 			}
// 		],
// 		otherSteps:
// 			'Enhanced hourly sanitizing procedure and deep cleaning four times a day\n\nReinforcement of proper handwashing every 15 minutes\n\nOption for guests to request wrapped utensils\n\nTraining for associates to identify potential symptoms and a clear reporting protocol\n\nHealth and temperature assessments for associates to ensure they are healthy for work\n',
// 		recommendations: {
// 			positive: {
// 				total: 0,
// 				categories: {}
// 			}
// 		},
// 		safetyMeasures: {},
// 		segment: {
// 			name: 'QSR',
// 			id: 6
// 		},
// 		googlePlacesId: 'ChIJ_3cCutO99YgRSRxR85Crwk4',
// 		id: '2e60cedd-bf24-589a-b878-bb9e3d4daa22',
// 		brand: {
// 			name: 'Panda Express',
// 			id: '1af51c12-8b8c-554f-bb7d-cf1dc29f7c90'
// 		},
// 		menuSpecialties: [
// 			{
// 				name: 'Desserts',
// 				id: 364
// 			},
// 			{
// 				name: 'Seafood',
// 				id: 379
// 			}
// 		],
// 		images: {
// 			logoImage: {
// 				url:
// 					'https://dinova-restaurants-dev.imgix.net/restaurants/brands/1af51c12-8b8c-554f-bb7d-cf1dc29f7c90/images/logo/logo.jpg'
// 			},
// 			giftcard: {
// 				url:
// 					'https://dinova-restaurants-dev.imgix.net/restaurants/brands/1af51c12-8b8c-554f-bb7d-cf1dc29f7c90/images/giftcard/panda.png'
// 			},
// 			cuisineImage: {
// 				url:
// 					'https://dinova-restaurants-dev.imgix.net/restaurants/brands/1af51c12-8b8c-554f-bb7d-cf1dc29f7c90/images/main/Orange-Chicken.jpg'
// 			},
// 			logo:
// 				'https://dinova-restaurants-dev.imgix.net/restaurants/brands/1af51c12-8b8c-554f-bb7d-cf1dc29f7c90/images/logo/logo.jpg',
// 			main:
// 				'https://dinova-restaurants-dev.imgix.net/restaurants/brands/1af51c12-8b8c-554f-bb7d-cf1dc29f7c90/images/main/Orange-Chicken.jpg'
// 		},
// 		address: {
// 			country: {
// 				iso: 'US'
// 			},
// 			city: 'Lawrenceville',
// 			street: ['900 Duluth Hwy. 120'],
// 			postalCode: '30042',
// 			state: {
// 				abbreviation: 'GA'
// 			}
// 		},
// 		social: {
// 			youtube: 'https://www.youtube.com/user/PandaExpressTV',
// 			twitter: 'https://twitter.com/PandaExpress',
// 			facebook: 'https://www.facebook.com/PandaExpress',
// 			instagram: 'https://www.instagram.com/officialpandaexpress/'
// 		},
// 		cuisine: {
// 			name: 'Chinese',
// 			id: 7
// 		},
// 		dutyOfCareUpdateDate: '2020-06-23T04:00:00.000Z',
// 		lastUpdate: {
// 			date: '2023-08-23T15:36:36.664Z'
// 		},
// 		name: 'Panda Express ',
// 		location: {
// 			lon: -84.0236578,
// 			lat: 33.9671833
// 		},
// 		integrations: {
// 			zuppler: {
// 				id: null
// 			}
// 		},
// 		contacts: {
// 			general: {
// 				numbers: {
// 					main: '(770) 682-3910',
// 					fax: '770-682-3911'
// 				},
// 				email: '',
// 				url: 'http://www.pandaexpress.com/'
// 			}
// 		},
// 		status: 1,
// 		distance: {
// 			span: '2.248692643789853',
// 			units: 'mi'
// 		},
// 		amenities: {
// 			delivery: {
// 				types: [
// 					{
// 						name: '3rd Party',
// 						id: 202
// 					}
// 				],
// 				url: 'https://order.pandaexpress.com/'
// 			},
// 			entertainment: {
// 				alcohol: null,
// 				music: null,
// 				happyHour: null,
// 				seating: null
// 			},
// 			onlineOrdering: {
// 				url: 'https://orders.pandaexpress.com/'
// 			},
// 			catering: {
// 				types: [
// 					{
// 						name: 'Self-Service',
// 						id: 1
// 					}
// 				],
// 				url: 'https://orders.pandaexpress.com/mp/nd/sites/desktop/Dashboard/'
// 			}
// 		}
// 	}
// ]);

/**
 * Redux_PointsElegibilityModalModalState
 * @interface Redux_PointsElegibilityModalModalState
 * @property {boolean} [show=false]
 */

export const Tabs = {
	CREATE: 'create',
	EDIT_SELECT: 'edit_select',
	NOTIFICATIONS: 'notifications'
};

const mock_temp_guide = {
	id: '',
	company: '',
	name: '',
	location: '',
	keywordSearch: '',
	selectedRestaurants: [] // Array.from(mockSelectedRestaurants) // new Set()
};

const initialTempGuide = {
	id: '',
	company: '',
	name: '',
	location: '',
	keywordSearch: '',
	selectedRestaurants: []
};

const initialEditQuery = {
	company: '',
	name: '',
	location: ''
};

const adminDiningGuidesInitialState = {
	// show: false,
	// confirmHandler: () => {}
	editQuery: initialEditQuery,
	editSearchQuery: '',
	originalGuide: mock_temp_guide,
	tempGuide: mock_temp_guide,
	selectFlowActiveTab: Tabs.CREATE,
	tempGuideErrors: {
		company: false,
		name: false,
		location: false
	}
};

export default (state = adminDiningGuidesInitialState, action) => {
	switch (action.type) {
		case t.SET_SELECT_FLOW_ACTIVE_TAB: {
			const { tab } = action.payload;
			return {
				...state,
				selectFlowActiveTab: tab
			};
		}

		case t.CLEAR_DINING_GUIDE: {
			return {
				...adminDiningGuidesInitialState,
				originalGuide: initialTempGuide,
				tempGuide: initialTempGuide,
				selectFlowActiveTab: state.selectFlowActiveTab,
				editQuery: state.editQuery
			};
		}

		case t.ADD_RESTAURANT_FOR_GUIDE: {
			const { restaurant } = action.payload;

			const newSelectedRestaurants = new Set([...state.tempGuide.selectedRestaurants, restaurant]);
			return {
				...state,
				tempGuide: {
					...state.tempGuide,
					selectedRestaurants: Array.from(newSelectedRestaurants)
				}
			};
		}

		case t.REMOVE_RESTAURANT_FOR_GUIDE: {
			const { restaurant } = action.payload;
			const newSelectedRestaurants = new Set([...state.tempGuide.selectedRestaurants]);
			const itemToRemove = newSelectedRestaurants.find(r => r.id === restaurant.id);
			newSelectedRestaurants.delete(itemToRemove);
			return {
				...state,
				tempGuide: {
					...state.tempGuide,
					selectedRestaurants: Array.from(newSelectedRestaurants)
				}
			};
		}

		case t.SET_SEARCH_QUERY: {
			const { editQuery } = action.payload;
			return {
				...state,
				editQuery: {
					...state.editQuery,
					...editQuery
				}
			};
		}

		case t.CLEAR_EDIT_GUIDE_QUERY: {
			return {
				...state,
				editQuery: initialEditQuery
			};
		}

		case t.UPDATE_TEMP_GUIDE: {
			const { tempGuide, tempGuideErrors } = action.payload;
			return {
				...state,
				tempGuide: {
					...state.tempGuide,
					...tempGuide
				},
				tempGuideErrors: {
					...state.tempGuideErrors,
					...tempGuideErrors
				}
			};
		}

		case t.CLEAR_TEMP_GUIDE_SELECTED_RESTAURANTS: {
			return {
				...state,
				tempGuide: {
					...state.tempGuide,
					selectedRestaurants: []
				}
			};
		}

		case t.CLEAR_DINING_GUIDE_EDITS: {
			return {
				...state,
				tempGuide: state.originalGuide
			};
		}

		default:
			return state;
	}
};
