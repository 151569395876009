import { createBrowserHistory } from 'history';
import { hasProp } from 'utils/object';

// The global history object that is passed to the app Router as the history prop.
export const history = createBrowserHistory();

/**
 * handleNavLinkClick should be attatched to all header NavLinks.  We want to catch any route change and prevent uneccesary changes or append extra info when required.
 * @param  {string} nextURL The nav items "to" prop.
 * @param  {event}  e       The click event.
 * @return {null}           Will prevent route change or modify route params as needed.
 */
export const handleNavLinkClick = (nextURL, e) => {
	const currentURL = window.location.href;
	const nextFullURL = e.target.href;

	if (currentURL === nextFullURL) {
		// If route change to route we are already on, ignore.
		e.preventDefault();
	} else if (nextURL.includes('/helpfaq') && currentURL.includes('/helpfaq')) {
		// If already on helpfaq page, and user clicks help nav icon, do nothing.
		e.preventDefault();
	} else if (nextURL.includes('/login') && window.location.pathname !== '/login') {
		// If user on page other than '/login' and clicks the '/login' nav link, append redirect info so user is taken back to original page after login.
		e.preventDefault();

		let redirect = window.location.href.replace(window.location.origin, '');
		if (redirect.indexOf('logout') > -1) {
			redirect = false;
		}

		if (redirect) {
			history.push({
				pathname: '/login',
				search: `?redirect=${redirect}`
			});
		} else {
			history.push('/login');
		}
	}
};

/**
 * [isNavItemActive description]
 * @param  {INavItem}  item               A navItem config object.
 * @param  {Boolean} [isMobileLeft=false] If the current render cycle is inside of the left mobile nav menu. (HamburgerMenu)
 * @return {Boolean}                      Returns true if nav item should show as active, false if not.
 */
export const isNavItemActive = (item, isMobileLeft = false) => {
	const useMatchMobileLeft = hasProp(item, 'useMatchMobileLeft') ? item.useMatchMobileLeft : true;
	const { url, matches } = item;

	let currentLocation = window.location.pathname;
	if (url === undefined) {
		return false;
	}

	if (url === undefined) {
		return false;
	}

	let isActive =
		currentLocation.indexOf(url) > -1 &&
		(currentLocation.length === url.length || currentLocation.length === `${url}/`.length); // for if this is a partial path match

	if (isMobileLeft && !useMatchMobileLeft) {
		// only use matches in left mobile nave if item is configured to do so.  Ex: rewuards - in left mobile nave we do not want to have "redmption" and "myDinova REWARDS" active at the same time.  But for Help we only have one nav item, so that should be active for all matches.
		return isActive;
	} else if (matches && !isActive) {
		let matchFound = false,
			i = 0;
		while (!matchFound && i < item.matches.length) {
			matchFound = currentLocation.indexOf(item.matches[i]) > -1;
			i++;
		}
		isActive = matchFound;
	}
	return isActive;
};
