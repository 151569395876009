import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'utils/debounce';
import { InputField } from 'components/organisms';
import { PulseLoader } from 'react-spinners';
import classnames from 'classnames';
import { programaticBlurWindowEvent } from 'utils/windowEvents';
import { isMobile } from 'utils/browser';
import poweredByGoogle from 'assets/images/powered_by_google_on_non_white.png';
import poweredByGoogleWhite from 'assets/images/powered_by_google_on_white.png';

class LocationAutoComplete extends Component {
	constructor(props) {
		super(props);

		this.fetchLocation = debounce(this.fetchLocation, 300);
		this.wrapper = React.createRef();
		this.list = React.createRef();

		this.state = {
			hasFocus: false,
			isSelectingFromItemClick: false
		};
	}

	currentLocationSuggestion = {
		description: this.props.translate('Home.cateringSearch.locationInput.placeholder')
	};

	noSuggestionsFoundItem = { description: this.props.translate('ConnectToPoints.no-suggestions') };

	setRef = cmp => (this.wrapper = cmp);

	componentDidMount = () => {
		this.props.initLocationAutoCompleteInstance(this.currentLocationSuggestion, this.noSuggestionsFoundItem);
		if (this.props.initialValue) {
			this.fetchLocation(this.props.initialValue, false);
		}
	};

	componentDidUpdate = prevProps => {
		if (!prevProps.initialValue && this.props.initialValue) {
			this.fetchLocation(this.props.initialValue, false);
		}
	};

	fetchLocation = (search, showLoader = true) => {
		if (
			search === this.currentLocationSuggestion.description ||
			search === this.noSuggestionsFoundItem.description ||
			search === ''
		) {
			return;
		}

		this.props.fetchLocationCompletions(search, showLoader);
	};

	onChange = (inputProps, e) => {
		inputProps.input.onChange(e, e);

		this.fetchLocation(e.target.value);
	};

	onMouseEnter = (item, index) => {
		this.props.setActiveSuggestion(index);
	};

	onWrapperMouseOver = () => {
		this.setState({ isSelectingFromItemClick: true });
	};

	onWrapperMouseLeave = () => {
		this.setState({ isSelectingFromItemClick: false });
	};

	onBlur = e => {
		if (this.state.isSelectingFromItemClick) {
			e.stopPropagation();
			if (isMobile() && this.props.onBlur) {
				if (this.props.activeItem) {
					e.target.value = this.props.suggestions[this.props.activeItem].description;
				}

				this.props.onBlur(e);
			}
		} else {
			this.setState({ hasFocus: false });
			if (this.props.onBlur) {
				this.props.onBlur();
			}
		}
	};

	onFocus = () => {
		this.setState({ hasFocus: true });
		if (this.props.onFocus) {
			this.props.onFocus();
		}
	};

	onItemClick = item => {
		this.props.reduxField.input.onChange(item.description, { target: { value: item.description } });
		this.props.suggestionsItemClick(item);

		this.setState({
			isSelectingFromItemClick: false,
			hasFocus: false
		});

		programaticBlurWindowEvent(`${this.props.id}-autocomplete`);
	};

	getReduxFieldProps = () => {
		let input, meta;
		if (!this.props.reduxField) {
			input = {};
			meta = { valid: true };
		} else {
			input = this.props.reduxField.input;
			meta = this.props.reduxField.meta;
		}

		return { input, meta };
	};

	render() {
		let { input, meta } = this.getReduxFieldProps();
		const {
				error,
				hasRecentSearchData,
				label,
				onErrorUsePlacehodler,
				placeholder,
				pulseLoaderColor,
				recentSearches
			} = this.props,
			props = this.props.reduxField,
			showLoading = this.props.showLoader && this.props.suggestions.length === 0,
			hasSuggestions = this.props.suggestions.length > 0,
			ItemComponent = this.props.itemCompnent || false;

		return (
			<InputField
				{...input}
				id={`${this.props.id}-autocomplete`}
				dataCy={this.props.dataCy}
				refSetter={this.setRef}
				type="typeahead"
				onBlur={this.onBlur.bind(this)}
				onFocus={this.onFocus}
				onChange={this.onChange.bind(this, props)}
				onMouseOver={this.onWrapperMouseOver}
				onMouseLeave={this.onWrapperMouseLeave}
				placeholder={placeholder}
				hasError={!meta.valid}
				error={error || meta.error}
				label={label}
				onHelpClick={this.onHelpClick}
				hasRecentSearchData={hasRecentSearchData}
				recentSearches={recentSearches}
				onErrorUsePlacehodler={onErrorUsePlacehodler}
			>
				<div className={classnames('no-suggestions', !showLoading && 'hidden')}>
					<PulseLoader sizeUnit={'px'} size={10} color={pulseLoaderColor} loading={true} />
				</div>
				<ul
					className={classnames('suggestions', (!hasSuggestions || !this.state.hasFocus) && 'hidden')}
					ref={c => (this.list = c)}
				>
					{this.props.suggestions.map((s, index) => {
						// const isCurrentLocation = s.description === this.currentLocationSuggestion.description;
						// const isNoResults = s.description === this.noSuggestionsFoundItem.description;
						const itemClassName = classnames(
							index === this.props.activeItem && 'suggestion-active',
							index === '0' && 'nearby'
							// (isCurrentLocation || isNoResults) && 'isLast'
						);

						return ItemComponent ? (
							<ItemComponent
								key={s.description}
								className={classnames(itemClassName, 'location-autocomplete-result')}
								onMouseEnter={this.onMouseEnter.bind(this, s, index)}
								onMouseLeave={this.onMouseEnter.bind(this, s, false)}
								onClick={this.onItemClick.bind(this, s, index)}
								dataCy={`location-autocomplete-result`}
								item={s}
							/>
						) : (
							<li
								key={s.description}
								onMouseEnter={this.onMouseEnter.bind(this, s, index)}
								onMouseLeave={this.onMouseEnter.bind(this, s, false)}
								onClick={this.onItemClick.bind(this, s, index)}
								data-cy={`location-autocomplete-result`}
								className={classnames(itemClassName, 'location-autocomplete-result')}
							>
								{s.description}
							</li>
						);
					})}

					{this.props.suggestions.length > 1 && (
						<li>
							<img src={this.props.whiteBackground ? poweredByGoogleWhite : poweredByGoogle} />
						</li>
					)}
				</ul>
			</InputField>
		);
	}
}

LocationAutoComplete.defaultProps = {
	pulseLoaderColor: '#1c871c',
	placeholder: 'Lorem.. Location'
};

LocationAutoComplete.propTypes = {
	activeItem: PropTypes.oneOf([false, PropTypes.number, PropTypes.string]),
	dataCy: PropTypes.string,
	error: PropTypes.string,
	fetchLocationCompletions: PropTypes.func.isRequired,
	geoLocationDenied: PropTypes.bool.isRequired,
	hasRecentSearchData: PropTypes.func,
	id: PropTypes.string.isRequired,
	initLocationAutoCompleteInstance: PropTypes.func.isRequired,
	initialValue: PropTypes.string,
	itemCompnent: PropTypes.node,
	label: PropTypes.any,
	onBlur: PropTypes.func,
	onErrorUsePlacehodler: PropTypes.bool,
	onFocus: PropTypes.func,
	placeholder: PropTypes.string,
	pulseLoaderColor: PropTypes.string,
	recentSearches: PropTypes.node,
	reduxField: PropTypes.object.isRequired,
	reduxId: PropTypes.string.isRequired,
	setActiveSuggestion: PropTypes.func.isRequired,
	showLoader: PropTypes.bool.isRequired,
	suggestions: PropTypes.array.isRequired,
	suggestionsItemClick: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired,
	whiteBackground: PropTypes.bool
};

export default LocationAutoComplete;
