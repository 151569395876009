import BusinessSearchForm from './ui-component';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { getLocation } from 'actions/geo_location';
import { getTranslate } from 'react-localize-redux';
import withSizes from 'react-sizes';
import { isSV } from 'utils/sizes';
import { hasProp } from 'utils/object';

/* eslint-disable no-mixed-spaces-and-tabs */
const getInitialValues = (state, ownProps) => {
	const { searchAccr, initialValues } = ownProps.redux; // 'businessSearch'
	if (initialValues) {
		return initialValues;
	}

	return hasProp(state, `form.${searchAccr}.values`) ? state.form[searchAccr].values : { find: '', near: '' };
};

const mapStateToProps = (state, ownProps) => ({
	geoLocation: state.geoLocation,
	formValues: getInitialValues(state, ownProps),
	initialValues: getInitialValues(state, ownProps),
	translate: getTranslate(state.locale)
});

const mapDispatchToProps = (dispatch, ownProps) => {
	const { formAccr } = ownProps.redux; // 'businessSearch'
	return {
		getLocation: async () => await getLocation(dispatch),
		setFieldValue: (field, val) => {
			dispatch(change(formAccr, field, val));
		}
	};
};

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

export default withRouter(
	withSizes(mapSizesToProps)(
		reduxForm({
			form: 'businessSearch',
			destroyOnUnmount: false
		})(connect(mapStateToProps, mapDispatchToProps)(BusinessSearchForm))
	)
);
