import ComingSoonBanner from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => {
	return {
		show: !window.location.pathname.includes('/admin'),
		translate: getTranslate(state.locale)
	};
};

export default connect(mapStateToProps)(ComingSoonBanner);
