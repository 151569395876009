import React, { createRef, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { history } from 'utils/router';
import { MapCard } from 'components/molecules';
import { isSV } from 'utils/sizes';
import { buildAboutRestaurantURL } from 'utils/url';
import { DinovaLogoMapMarker } from 'components/atoms';

const SimpleMapMarker = ({
	className = '',
	data,
	IMAGE_BASE_URL,
	// isDarylSearch,
	isRestaurant = true,
	lat,
	lng,
	mapId,
	onMarkerClick,
	saveSearchState
}) => {
	const isDiningGuides = window.location.pathname.includes('dining-guide');
	const iconRef = createRef();
	const cardRef = createRef();

	const [isActive, setIsActive] = useState(false);

	const onMapCardClick = useCallback(
		() => item => {
			saveSearchState();
			history.push(buildAboutRestaurantURL(item));
		},
		[saveSearchState]
	);

	// componentDidUpdate
	useEffect(() => {
		if (!data) {
			return;
		}
		const isMarkerActive = data.activePin === data.id,
			isCardActive = isMarkerActive && data.mapCardDisplayed;

		if (isCardActive) {
			positionMapCard();
			setIsActive(true);
		} else {
			setIsActive(false);
		}
	}, [data, isActive, positionMapCard]);

	const positionMapCard = useCallback(() => {
		if (isActive === true) {
			return;
		}
		// clear pos so we get accurate bounds data.
		cardRef.current.style.left = '';
		cardRef.current.style.top = '';
		// x = left to right
		// y = top to bottom
		let map = document.getElementById(mapId),
			mapBounds = map.getBoundingClientRect(),
			cardBounds = cardRef.current.getBoundingClientRect(),
			fallsRight = cardBounds.x + cardBounds.width > mapBounds.x + mapBounds.width,
			fallsLeft = cardBounds.x - cardBounds.width < mapBounds.x,
			fallsTop = cardBounds.y - cardBounds.height < mapBounds.y,
			fallsBottom = cardBounds.y + cardBounds.height > mapBounds.y + mapBounds.height;

		let iconWidth = isSV ? 25 : 25;
		let iconHeight = isSV ? 35 : 35;
		let iconPad = 10;

		if (fallsLeft && fallsRight) {
			cardRef.current.style.left = `-${(cardRef.current.offsetWidth + iconWidth) / 2}px`; // + 25 to compensate for marker Icon image.
			if (!fallsTop && !fallsBottom) {
				cardRef.current.style.top = `${iconHeight}px`;
			}
		} else if (fallsLeft) {
			cardRef.current.style.left = ``; // + 25 to compensate for marker Icon image.
		} else if (fallsRight) {
			cardRef.current.style.left = `-${cardRef.current.offsetWidth + iconWidth}px`; // + 25 to compensate for marker Icon image.
		}

		if (fallsTop) {
			cardRef.current.style.top = `${iconHeight}px`;
		}

		if (fallsBottom) {
			cardRef.current.style.top = `${(cardRef.current.offsetHeight + iconPad) * -1}px`;
		}
	}, [cardRef, isActive, mapId]);

	const onMarkerClickHandler = useCallback(
		event => {
			onMarkerClick({ event, childProps: { lat, lng, isRestaurant, ...data } });
		},
		[lat, lng, data, onMarkerClick, isRestaurant]
	);

	if (!data) {
		return null;
	}
	const isMarkerActive = data.activePin === data.id;
	const isCardActive = isMarkerActive && data.mapCardDisplayed;
	const classProps = classNames(
		'simple-map-marker',
		isMarkerActive && 'active',
		isCardActive && 'card-active',
		className
	);
	const heartIcon = `${IMAGE_BASE_URL}/icons/icon-map-pin-favorite-${isMarkerActive ? '' : 'in'}active.svg`;
	const pinIcon = `${IMAGE_BASE_URL}/icons/icon-map-pin-${isMarkerActive ? '' : 'in'}active.svg`;
	const mapMarkerSrc = data.favorite ? heartIcon : pinIcon;
	const imgSrc = mapMarkerSrc;
	const imgClassProps = 'marker';

	// if (!data.name) {
	// console.error('oops! the restaurant marker name is undefined', this.props);
	// }

	return (
		<div className={classProps} data-cy={isMarkerActive ? 'simple-map-marker-active' : 'simple-map-marker'}>
			{isDiningGuides && <DinovaLogoMapMarker markerRef={iconRef} onClick={onMarkerClickHandler} id={data.id} />}
			{!isDiningGuides && (
				<img
					key={`marker-img-${data.id}`}
					ref={iconRef.current}
					className={imgClassProps}
					src={imgSrc}
					onClick={onMarkerClickHandler}
					data-marker-zone
					alt={isMarkerActive ? 'active map item' : 'inavtive map item'}
				/>
			)}
			<MapCard
				isActive={isMarkerActive}
				key={`map-card-${data.id}`}
				refSetter={cardRef}
				data={data}
				onTransition={onMapCardClick(data)}
			/>
			<div key={`marker-name-${data.id}`} className="restaurant-name">
				{data.name}
			</div>
		</div>
	);
};

SimpleMapMarker.propTypes = {
	className: PropTypes.string,
	data: PropTypes.object,
	IMAGE_BASE_URL: PropTypes.string,
	isRestaurant: PropTypes.bool,
	lat: PropTypes.number,
	lng: PropTypes.number,
	mapId: PropTypes.string.isRequired, // The DOM el ID of the map this marker belongs to.  Requird to determine where to position marker card in realtion to the marker icon.
	onMarkerClick: PropTypes.func.isRequired,
	saveSearchState: PropTypes.func.isRequired
};

export default SimpleMapMarker;
