import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Text } from 'components/atoms';
import { useTranslate } from 'hooks';

const MapCard = ({ className = '', data, isActive, onTransition, refSetter }) => {
	const translate = useTranslate();

	const specialties = useMemo(() => {
		if (!data.menuSpecialties || data.menuSpecialties.length === 0) {
			return '';
		}
		let specialties = [];
		let i = 0;

		while (i < 5 && data.menuSpecialties[i]) {
			specialties.push(data.menuSpecialties[i].name);
			i++;
		}

		specialties = specialties.join(', ');

		return specialties;
	}, [data]);

	const onClick = useCallback(
		e => {
			e.preventDefault();
			onTransition &&
				onTransition({
					id: data.id,
					name: data.name,
					city: data.city,
					state: data.state,
					distance: data.distance
				});
		},
		[data, onTransition]
	);

	const classProps = classNames('map-card', className);

	let cardWidth = window.innerWidth - 30;
	if (cardWidth > 364) {
		cardWidth = 364;
	}

	let imgWidth = cardWidth - 16,
		cardStyle = { width: cardWidth },
		imgStyle = { width: imgWidth };

	return (
		<a
			className={classProps}
			style={cardStyle}
			onClick={onClick}
			ref={refSetter}
			data-cy={isActive ? 'map-card-active' : 'map-card'}
			id={`map-card-tooltip-${data.id}`}
		>
			<div className="img-wrapper">
				<img className="img" style={imgStyle} src={data.imgBanner} alt={`${data.name} food`} />
				{data.bannerText && <Text size="sm">{data.bannerText}</Text>}
			</div>
			<div className="info">
				<div className="restaurant-info">
					<span className="name" data-cy={isActive ? 'map-card-title-active' : 'map-card-title'}>
						{data.name}
					</span>
					<span className="cuisine">
						{data.cuisine}
						{specialties ? `, ${specialties}` : null}
					</span>
				</div>
				<span className="distance">
					{parseFloat(data.distance.span).toFixed(1) + ' '}
					{translate(`Units.${data.distance.units}`)}
				</span>
			</div>
		</a>
	);
};

MapCard.propTypes = {
	className: PropTypes.string,
	data: PropTypes.object.isRequired,
	isActive: PropTypes.bool,
	onTransition: PropTypes.func,
	refSetter: PropTypes.func
};

export default MapCard;
