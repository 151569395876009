import React, { useCallback, useMemo } from 'react';
import styles from './style.module.scss';
import PropTypes from 'prop-types';
import { InvisibleButton, Text } from 'components/atoms';
import { history } from 'utils/router';
import { useLocation } from 'react-router-dom';

const ComingSoonBanner = ({ show, translate }) => {
	const location = useLocation();
	let message = <Text className={styles.link}>{translate('Banner.comingSoon')}</Text>;

	const onClick = useCallback(() => {
		if (!location.pathname.includes('download')) {
			history.push('/we-moved');
		}
	}, [location.pathname]);

	const _show = useMemo(() => {
		return show && !location.pathname.includes('admin');
	}, [show, location.pathname]);

	return _show ? (
		<div className={styles.wrapper}>
			<InvisibleButton onClick={onClick}>
				<div className={styles.btnContent}>{message}</div>
			</InvisibleButton>
		</div>
	) : null;
};

ComingSoonBanner.propTypes = {
	show: PropTypes.bool,
	translate: PropTypes.func.isRequired
};

export default ComingSoonBanner;
