import { useEffect } from 'react';
import { useQuery as useReactQuery } from '@tanstack/react-query';
import axios from 'axios';
import { AUTH_REQUEST_HEADER, AUTH_REQUEST_HEADER_ID } from 'actions/utils';
export const USER_QUERY_KEY = 'user';
import { setIsLoading } from 'actions/loader';
import { useDispatch } from 'react-redux';
import useAuthContext from 'context/AuthContext';

/* interface useQueryProps {
  // Required
  queryHandler: (resData: any) => any;
  queryKey: string | [string, ...any]; if string, id of this react query instance.  if array, 0 index os query id, following indexes are query args
  url: string; // optional if urlBuilder is provided

  // Optional
	enabled?: boolean;  if false will act as a lazy query until flipped to true
	onSuccess?: (data: DataType) => void;
  quietLoading?: boolean = false
  reduxLoadingMsg?: string;
  reduxQueryDataAccr?: (dataRes: any) => any;
	requiresAuth?: boolean;
  setDataInRedux?: (dataRes: any) => void;
  setReduxIsLoading?: (isLoading: boolean, message: string) => void;
  urlBuilder: (queryParams: object) => string;
} */

// routes that require Auth
// users (get, patch)
// users/authorize/mfa (all)
// users/logout (get)
// users/password (put)
// user/connections (post)
// user/connections/id (patch, delete)
// users/rewards (get, post)
// users/transactions (get)
// clients/autocomplete (get)
// clients/id (get)
// users/recommendations (get)
// users/recommendations/restaurant-id (get, post)
// users/restaurants/favorite (get, post)
// users/restaurants/favorite/id (delete)

const useQuery = ({
	// Req
	queryHandler,
	queryKey,
	url,
	// Opt
	authType = 'token', // 'token' or 'id'
	enabled: enabledProp = true,
	onSuccess,
	queryFn,
	quietLoading,
	reduxLoadingMsg,
	reduxQueryDataAccr,
	requiresAuth = false,
	setDataInRedux,
	setReduxIsLoading,
	urlBuilder
} /*:useQueryProps*/ = {}) => {
	const dispatch = useDispatch();
	const { isAuthed } = useAuthContext();

	const enabled = requiresAuth ? Boolean(isAuthed && enabledProp) : enabledProp;

	const authHeaderFunc = authType === 'token' ? AUTH_REQUEST_HEADER : AUTH_REQUEST_HEADER_ID;

	// Queries
	const query = useReactQuery({
		queryKey: typeof queryKey === 'string' ? [queryKey] : queryKey,
		queryFn: queryFn
			? queryFn
			: ({ queryKey }) => {
					const URL = urlBuilder ? urlBuilder(queryKey) : url;

					return axios.get(URL, authHeaderFunc()).then(queryHandler);
			  },
		enabled: requiresAuth ? Boolean(isAuthed && enabled) : enabled,
		refetchOnWindowFocus: false
	});

	// For legacy redux full screen loader
	useEffect(() => {
		if (setReduxIsLoading && !quietLoading) {
			setReduxIsLoading(query.isLoading, reduxLoadingMsg || 'Loading...');
		} else if (reduxLoadingMsg && !quietLoading) {
			dispatch(setIsLoading(query.isLoading, reduxLoadingMsg));
		}
	}, [query.isLoading, setReduxIsLoading, quietLoading, dispatch, reduxLoadingMsg]);

	// For setting legacy api data in
	useEffect(() => {
		// TODO: Remove from redux state then remove this setter
		if (reduxQueryDataAccr && setDataInRedux && query.data) {
			setDataInRedux(reduxQueryDataAccr(query));
		}
		if (query?.data) {
			onSuccess && onSuccess(query?.data);
		}
	}, [onSuccess, query, reduxQueryDataAccr, setDataInRedux]);

	return {
		...query,
		isLoading: !query.isFetched || query.isLoading
	};
};

export default useQuery;
