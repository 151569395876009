import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { LoadingBase, Text } from 'components/atoms';
import { ActiveFiltersBar, CollapsiblePanel } from 'components/molecules';
import { BusinessSearchFilter } from 'components/organisms';
import { Button } from 'reactstrap';
import { useTranslate } from 'hooks';

const SearchPageFilterPanel = ({
	activeFilters,
	addFilter,
	clearFilters,
	isLoading,
	isSV,
	showFilter,
	removeFilter,
	filteredResults,
	toggleShowFilter,
	userFilters
}) => {
	const translate = useTranslate();
	const filterClassProps = classnames(isSV && showFilter ? '' : !isSV ? '' : 'slide-up');

	const hasActiveFilters = activeFilters?.length > 0;

	return (
		<CollapsiblePanel isOpen={showFilter}>
			{isLoading && (
				<div style={{ marginTop: '50px' }}>
					<LoadingBase />
				</div>
			)}
			{!isLoading && (
				<>
					<div className="flex align-baseline filter-title">
						<Text className="mb-0 bold mr-2">{translate('FilterModal.title')}</Text>
						{hasActiveFilters && (
							<Button className="text" onClick={clearFilters} data-cy="clear-filters">
								{translate('FilterModal.clear')}
							</Button>
						)}
					</div>
					<ActiveFiltersBar activeFilters={activeFilters} removeFilter={removeFilter} />
					<BusinessSearchFilter
						addFilter={addFilter}
						removeFilter={removeFilter}
						className={filterClassProps}
						clearFilters={clearFilters}
						filteredResults={filteredResults}
						filters={userFilters}
						isLoading={isLoading}
						toggleShowFilter={toggleShowFilter}
					/>
				</>
			)}
		</CollapsiblePanel>
	);
};

SearchPageFilterPanel.defaultProps = {};

/**
 * SearchPageFilterPanelProps
 * @typedef {Object} SearchPageFilterPanelProps
 */
SearchPageFilterPanel.propTypes = {
	activeFilters: PropTypes.array.isRequired,
	addFilter: PropTypes.func.isRequired,
	clearFilters: PropTypes.func.isRequired,
	filteredResults: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
	isSV: PropTypes.bool.isRequired,
	removeFilter: PropTypes.func.isRequired,
	showFilter: PropTypes.bool.isRequired,
	userFilters: PropTypes.object.isRequired,
	toggleShowFilter: PropTypes.func.isRequired
};

export default SearchPageFilterPanel;
