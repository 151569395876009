import SearchPageFilterPanel from './ui-component';
import { connect } from 'react-redux';
import withSizes from 'react-sizes';
import { isSV } from 'utils/sizes';
import { toggleShowFilter } from 'actions/search_page';

const mapStateToProps = state => ({
	showFilter: state.searchPage.showFilter
});

const mapDispatchToProps = dispatch => ({
	toggleShowFilter: show => dispatch(toggleShowFilter(show))
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

export default connect(mapStateToProps, mapDispatchToProps)(withSizes(mapSizesToProps)(SearchPageFilterPanel));
