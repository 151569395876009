import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { hasProp } from 'utils/object';
import { useTranslate } from 'hooks';
import { ampli } from 'ampli';
/**
 * Configure how to render Google Map.
 * @type {Object}
 * @property {boolean} scrollwheel Disables users ability to zoom on sroll.
 * @property {boolean} disableDoubleClickZoom Disables users ability to zoom on double click.
 * @property {boolean} draggable Disables users ability to move the map.
 * @property {boolean} fullscreenControl Disables users ability to enter map in fullscreen.
 * @property {boolean} scaleControl Disables users ability scale.
 * @property {boolean} zoomControl Prevents zoom in/out buttons from rendering.
 */
const mapOptions = {
	scrollwheel: false,
	disableDoubleClickZoom: true,
	draggable: false,
	fullscreenControl: false,
	scaleControl: false,
	zoomControl: false
};

/**
 * Using redux state.details, show the restaurant location in a map, show the address of the restaurant, provide a button to link user to get directions to the restaurant.
 *
 * @param {Props_AddressAndDirections} props
 */
const AddressAndDirections = ({ restaurant, API_KEY }) => {
	const translate = useTranslate();

	const { addresses, pinLocation, title } = useMemo(() => {
		let _addresses = hasProp(restaurant, 'address') ? restaurant.address : false;
		if (_addresses) {
			_addresses = [
				..._addresses.street,
				`${_addresses.city}, ${
					hasProp(_addresses, 'state.abbreviation') ? _addresses.state.abbreviation : ''
				} ${_addresses.postalCode}`
			];
		} else {
			_addresses = [];
		}
		return { addresses: _addresses, pinLocation: restaurant.location, title: restaurant.name };
	}, [restaurant]);
	/**
	 * Creates get directions link to map.google.com.
	 * @return {string} Google maps get directions link.
	 */
	const getDirectionsLink = useCallback(() => {
		return `https://maps.google.com/?saddr=Current+Location&daddr=${pinLocation.lat},${pinLocation.lon}`;
	}, [pinLocation]);

	/**
	 * Creates the dinova icon to be used as the map marker indicating the restaurants location.
	 * @return {object} React Component.
	 */
	const mapMarker = useCallback(() => {
		const icon_url = encodeURI('https://dinova-images-dev.imgix.net/icons/icon-selected-map-marker-64.png');

		// Disable eslint so lat and lng don't trigger the unrecognized prop error
		/* eslint-disable */
		return (
			<div className="map-marker" lat={pinLocation.lat} lng={pinLocation.lon}>
				<img src={icon_url} alt={`${title} map marker`} />
				<p className="map-marker-label">{title}</p>
			</div>
		);
		/* eslint-enable */
	}, [pinLocation, title]);

	/**
	 * Creates the address component.
	 * @return {object} React Component.
	 */
	const addressComponent = useCallback(() => {
		return (
			<div className="addresses column" data-cy="address">
				{addresses.map((a, i) => (
					<span key={`address-${i}`} className="address">
						{a}
					</span>
				))}
			</div>
		);
	}, [addresses]);
	/**
	 * Creates the get directions button.
	 * @return {object} React Component.
	 */
	const directionsButton = useCallback(() => {
		return (
			<a
				className="direction-btn"
				href={getDirectionsLink()}
				target="_blank"
				rel="noopener noreferrer"
				data-cy="get-directions"
				onClick={() => ampli.restaurantDirectionsTapped()}
			>
				{translate('Details.direction')}
			</a>
		);
	}, [getDirectionsLink, translate]);

	const center = {
		lat: pinLocation.lat,
		lng: pinLocation.lon
	};

	return (
		<div className="details-location" data-cy="details-location">
			<div className="map-wrapper">
				<GoogleMapReact bootstrapURLKeys={{ key: API_KEY }} center={center} zoom={13} options={mapOptions}>
					{mapMarker()}
				</GoogleMapReact>
			</div>
			<div className="map-info flex justify-between">
				{addressComponent()}
				<div className="right-column column align-end">{directionsButton()}</div>
			</div>
		</div>
	);
};

AddressAndDirections.defaultProps = {
	details: {
		location: {
			lat: 0,
			lon: 0
		},
		addresses: []
	}
};

/**
 * {@link AddressAndDirections} Props
 * Note: This component only utilizes state.details.location and state.details.addresses.
 *
 * @interface Props_AddressAndDirections
 * @property {string} API_KEY The api key for google maps.
 * @property {object} location React router location object.
 * @property {Redux_RestaurantDetails} details {@link RestaurantDetails} The redux state.details restaurant.
 */
AddressAndDirections.propTypes = {
	// Required
	restaurant: PropTypes.object.isRequired,
	API_KEY: PropTypes.string.isRequired
};

export default AddressAndDirections;
