import BusinessSearchMap from './ui-component';
import { connect } from 'react-redux';
import { setActivePin, setMapCardDisplayed } from 'actions/search_page';
import {
	reCenterToGeoLocation,
	setAreaSearchDisplayed,
	setCenter,
	setCenterPan,
	setZoom
} from 'actions/business_search_map';
import withSizes from 'react-sizes';
import { isSV } from 'utils/sizes';

const mapStateToProps = (state, ownProps) => {
	/**
	 * redux = {
	 * 	 pageDataAccr: string Ex: 'searchPage'
	 *   mapDataAccr: string Ex: 'businessSearchMap'
	 * }
	 *
	 */
	const { pageDataAccr, mapDataAccr } = ownProps.redux;
	return {
		activePin: state[pageDataAccr].activePin,
		areaSearchDisplayed: state[mapDataAccr].areaSearchDisplayed,
		center: state[mapDataAccr].center,
		centerPan: state[mapDataAccr].centerPan,
		centerToGeoLocation: state[mapDataAccr].centerToGeoLocation,
		geoLocation: {
			lat: state.geoLocation.lat,
			lng: state.geoLocation.lng
		},
		geoLocationDenied: state.geoLocation.wasDenied,
		hasGeoLocation: state.geoLocation.hasGeoLocation,
		mapCardDisplayed: state[pageDataAccr].mapCardDisplayed,
		rehydrateFromSaveState: state[pageDataAccr].rehydrateFromSaveState,
		showList: state[pageDataAccr].showList,
		zoom: state[mapDataAccr].zoom
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	const actions = ownProps.actions;
	const _setCenter = actions?.setCenter ? actions.setCenter : setCenter;
	const _setCenterPan = actions?.setCenterPan ? actions.setCenterPan : setCenterPan;
	const _setZoom = actions?.setZoom ? actions.setZoom : setZoom;
	const _setAreaSearchDisplayed = actions?.setAreaSearchDisplayed
		? actions.setAreaSearchDisplayed
		: setAreaSearchDisplayed;
	const _reCenterToGeoLocation = actions?.reCenterToGeoLocation
		? actions.reCenterToGeoLocation
		: reCenterToGeoLocation;

	return {
		reCenterToGeoLocation: shouldCenter => dispatch(_reCenterToGeoLocation(shouldCenter)),
		setActivePin: pin => dispatch(setActivePin(pin)),
		setAreaSearchDisplayed: isDisplayed => dispatch(_setAreaSearchDisplayed(isDisplayed)),
		setCenter: async center => await _setCenter(dispatch, center),
		setCenterPan: center => dispatch(_setCenterPan(center)),
		setMapCardDisplayed: isDisplayed => dispatch(setMapCardDisplayed(isDisplayed)),
		setZoom: zoom => dispatch(_setZoom(zoom))
	};
};

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

export default withSizes(mapSizesToProps)(connect(mapStateToProps, mapDispatchToProps)(BusinessSearchMap));
