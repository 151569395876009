import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { LoadingBase } from 'components/atoms';

const List = ({ align, className, isLoading, items, itemComponent, itemComponentProps, listId, style }) => {
	const ItemComponent = itemComponent,
		classProps = classnames('list', className, align);

	return (
		<div className={classProps} style={style}>
			{isLoading ? (
				<LoadingBase />
			) : (
				items.map((item, index) => (
					<ItemComponent item={item} key={`${listId}-${index}`} {...(itemComponentProps || {})} />
				))
			)}
		</div>
	);
};

List.defaultProps = {
	align: 'vertical',
	items: []
};

List.propTypes = {
	className: PropTypes.string,
	align: PropTypes.oneOf(['vertical', 'horizontal']),
	isLoading: PropTypes.bool.isRequired,
	items: PropTypes.array.isRequired,
	itemComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
	itemComponentProps: PropTypes.any,
	listId: PropTypes.string.isRequired,
	style: PropTypes.object
};

export default List;
