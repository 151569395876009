import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field, SubmissionError } from 'redux-form';
import { LocationAutoComplete, ReduxFormInput } from 'components/organisms';
import { Button } from 'reactstrap';
import qs from 'querystring';
import { RecentSearches } from 'components/molecules';
import { updateRecentSearchs } from 'utils/search';
import { programaticBlurWindowEvent } from 'utils/windowEvents';
import { isMobile } from 'utils/browser';
import styles from './styles.module.scss';
import { getLowerCasedParams } from 'utils/url';
import { getLocationNameFromLatLng } from 'utils/google_autocomplete_service';

class BusinessSearchForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			fields: null,
			hasFindRecentSearchData: false,
			hasNearRecentSearchData: false,
			isRecentClick: false,
			near: props.initialNearValue || '',
			find: ''
		};

		this.submitBtnRef = React.createRef();
	}

	componentDidMount = () => {
		document.addEventListener('keyup', this.handleKeyUp);
		this.initFields();
		const { center, keywords, near, location } = getLowerCasedParams();

		if (keywords) {
			this.props.setFieldValue('find', keywords);
		}
		if (near) {
			this.props.setFieldValue('near', near);
		} else if (center) {
			let [lat, lng] = center.split(',');
			lat = parseFloat(lat);
			lng = parseFloat(lng);
			if (isNaN(lat) || isNaN(lng)) {
				return;
			}

			getLocationNameFromLatLng({ lat, lng }).then(name => {
				Boolean(name) && this.props.setFieldValue('near', name);
			});
		} else if (location) {
			let [lat, lng] = location.split(',');
			lat = parseFloat(lat);
			lng = parseFloat(lng);
			if (isNaN(lat) || isNaN(lng)) {
				return;
			}

			getLocationNameFromLatLng({ lat, lng }).then(name => {
				Boolean(name) && this.props.setFieldValue('near', name);
			});
		}
	};

	componentDidUpdate = () => {
		if (this.props.initialNearValue && !this.state.near) {
			this.setState({ near: this.props.initialNearValue });
			this.props.setFieldValue('near', this.props.initialNearValue);
		}
	};

	componentWillUnmount = () => {
		document.removeEventListener('keyup', this.handleKeyUp);
	};

	inputs = ['find', 'near'];

	onFocusOut = e => {
		if (this.state.isRecentClick) {
			if (e.target.name === 'near') {
				this.props.setFieldValue('near', this.state.near);
			} else if (e.target.name === 'business-find') {
				this.props.setFieldValue('find', this.state.find);
			}
		} else {
			if (e.target.name === 'near') {
				programaticBlurWindowEvent(`business-near-autocomplete`);
				this.setState({ near: e.target.value });
			} else if (e.target.name === 'business-find') {
				this.setState({ find: e.target.value });
				programaticBlurWindowEvent(`business-find`);
			} else {
				return;
			}
		}
	};

	handleKeyUp = e => {
		if (e.key === 'Enter') {
			if (isMobile()) {
				this.form.dispatchEvent(new Event('submit', { cancelable: true }));
			}
			if (e.target.name === 'business-near-autocomplete') {
				programaticBlurWindowEvent(`business-near-autocomplete`);
			} else if (e.target.name === 'business-find') {
				programaticBlurWindowEvent(`business-find`);
			} else {
				return;
			}
		} else {
			return;
		}
	};

	onRecentSearchLoad = (type, hasRecentSearchData) => {
		if (type === 'find') {
			this.setState({ hasFindRecentSearchData: hasRecentSearchData });
		} else {
			this.setState({ hasNearRecentSearchData: hasRecentSearchData });
		}
	};

	updateRecentSearchCache = () => {
		const { find, near } = this.props.formValues;

		let message = '';
		if (find && near) {
			message = `${find} near ${near}`;
		} else if (!find && near) {
			message = near;
		} else if (find && !near) {
			message = `${find} near me`;
		} else {
			return;
		}

		const newSearch = {
			keywords: find,
			near,
			message
		};

		updateRecentSearchs('business', newSearch);
	};

	handleRecentSearchMouseEnter = e => {
		this.setState({ isRecentClick: true, find: e.target.dataset.find, near: e.target.dataset.near });
	};

	handleRecentSearchMouseLeave = () => {
		this.setState({ isRecentClick: false });
	};

	onRecentClick = data => {
		const { near, find } = data;
		if (find && !near) {
			this.props.setFieldValue('find', find);
			programaticBlurWindowEvent(`business-find`);
		} else if (!find && near) {
			this.props.setFieldValue('near', near);
			programaticBlurWindowEvent(`business-near-autocomplete`);
		}

		setTimeout(() => {
			this.submitBtnRef.current?.click();
		}, 300);
	};

	onBlur = (originalBlurFunc, e) => {
		if (this.state.isRecentClick) {
			if (e.target.id === 'business-near-autocomplete') {
				e.target.value = this.state.near;
			} else if (e.target.id === 'business-find') {
				e.target.value = this.state.find;
			}
		}

		originalBlurFunc(e);
		this.form.dispatchEvent(new Event('submit', { cancelable: true }));
	};

	initFields = () => {
		const fields = this.inputs.map((input, index) => {
			return (
				<Field
					name={input}
					key={`business-form-field-${index}`}
					component={field => {
						// console.log('BusinessSearchForm field', index, input, field);
						// if (this.props.setupFlow) {
						// 	field.input.value = input === 'near' ? 'Chicago, IL, USA' : 'Pizza';
						// }
						if (isMobile() && this.isSearchPage()) {
							this[input] = field.input;
							field.input.onBlur = this.onBlur.bind(this, field.input.onBlur);
						}
						if (input === 'near') {
							return (
								<LocationAutoComplete
									whiteBackground={this.props.whiteBackground}
									reduxId={this.props.reduxId}
									reduxField={field}
									label={
										this.isSearchPage()
											? false
											: this.props.translate('Home.businessSearch.nearLabel')
									}
									onBlur={field.input.onBlur}
									placeholder={this.props.translate('Home.businessSearch.nearPlaceholder')}
									initialValue={field.input.value}
									id={`business-${input}`}
									dataCy={`business-${input}-field`}
									hasRecentSearchData={() => this.state.hasNearRecentSearchData}
									pulseLoaderColor={'#ffffff'}
									onErrorUsePlacehodler={window.location.href.indexOf('search') > -1}
									recentSearches={
										<RecentSearches
											type="business"
											recent="near"
											recentCallback={this.onRecentClick}
											onListLoad={this.onRecentSearchLoad.bind(this, 'near')}
											onMouseEnter={this.handleRecentSearchMouseEnter}
											onMouseLeave={this.handleRecentSearchMouseLeave}
										/>
									}
								/>
							);
						} else {
							return (
								<ReduxFormInput
									field={field}
									inputProps={{
										dataCy: `business-${input}-field`,
										name: `business-${input}`,
										id: `business-${input}`,
										error: 'Lorem.. Error',
										hasError: !field.meta.valid,
										label: this.isSearchPage()
											? false
											: this.props.translate('Home.businessSearch.findLabel'),
										placeholder: this.props.translate('Home.businessSearch.findPlaceholder'),
										hasRecentSearchData: () => this.state.hasFindRecentSearchData,
										recentSearches: (
											<RecentSearches
												type="business"
												recent="keywords"
												recentCallback={this.onRecentClick}
												onListLoad={this.onRecentSearchLoad.bind(this, 'find')}
												onMouseEnter={this.handleRecentSearchMouseEnter}
												onMouseLeave={this.handleRecentSearchMouseLeave}
											/>
										)
									}}
								/>
							);
						}
					}}
				/>
			);
		});

		this.setState({ fields });
	};

	submit = async vals => {
		if (!vals.near || vals.near === this.props.translate('Home.businessSearch.locationInput.placeholder')) {
			await this.doNearMeSearch(vals);
		} else {
			this.doSearchFromForm(vals);
		}
	};

	doSearchFromForm = async vals => {
		const params = {};

		if (vals.near) {
			params.near = vals.near;
		}

		if (vals.find) {
			params.keywords = vals.find;
		}

		this.updateRecentSearchCache();

		const baseUrl = this.props.baseUrl || '/search';
		this.props.history.push(`${baseUrl}?${qs.stringify(params)}#/`);

		const isSearchPage = window.location.href.indexOf('/search') > -1;
		const isDiningGuidesPage = window.location.href.indexOf('/admin/dining-guides') > -1;

		if ((isSearchPage || isDiningGuidesPage) && this.props.onSubmit) {
			this.props.onSubmit();
		}
	};

	doNearMeSearch = async () => {
		const { geoLocation } = this.props,
			{ hasGeoLocation, wasDenied } = geoLocation;
		if (!hasGeoLocation) {
			if (wasDenied) {
				this.showNoLocationError();
			} else {
				try {
					this.props.history.push(`/search/#/`);
				} catch (e) {
					this.showNoLocationError();
				}
			}
		} else {
			this.props.history.push(`/search/#/`);
		}
	};

	showNoLocationError = () => {
		throw new SubmissionError({
			near: 'No Geolocation or near search provided'
		});
	};

	isSearchPage = () => window.location.href.indexOf('/search') > -1;

	render() {
		const { appendButtons, isSV, submitButtonText } = this.props,
			classProps = classnames('business-search', appendButtons && 'append-buttons');

		return (
			<form
				ref={c => (this.form = c)}
				className={classProps}
				name="business-search"
				onSubmit={this.props.handleSubmit(this.submit)}
			>
				{this.state.fields}
				<input type="submit" hidden />
				<div className={classnames('btns-wrapper', styles.btnsWrapper)}>
					<Button
						color="primary"
						type="submit"
						data-cy="business-search-submit"
						className={isSV && this.isSearchPage() ? 'hidden' : ''}
						innerRef={this.submitBtnRef}
					>
						{submitButtonText}
					</Button>
					{appendButtons && appendButtons}
				</div>
			</form>
		);
	}
}

BusinessSearchForm.defaultProps = {};

BusinessSearchForm.propTypes = {
	appendButtons: PropTypes.node,
	baseUrl: PropTypes.string,
	formValues: PropTypes.shape({
		find: PropTypes.string,
		near: PropTypes.string
	}),
	geoLocation: PropTypes.object,
	handleSubmit: PropTypes.func.isRequired, // redux form
	history: PropTypes.object.isRequired,
	isSV: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func,
	reduxId: PropTypes.string.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	submitButtonText: PropTypes.string.isRequired,
	translate: PropTypes.func.isRequired,
	whiteBackground: PropTypes.bool,
	initialNearValue: PropTypes.string
	// setupFlow: PropTypes.bool
};

export default BusinessSearchForm;
