export const SET_ADMIN_DINING_SEARCH_MAP_CENTER = 'admin_dining_guides.SET_ADMIN_DINING_SEARCH_MAP_CENTER';
export const SET_ADMIN_DINING_SEARCH_MAP_ZOOM = 'admin_dining_guides.SET_ADMIN_DINING_SEARCH_MAP_ZOOM';
export const SET_ADMIN_DINING_AREA_SEARCH_BUTTON_DISPLAYED =
	'admin_dining_guides.SET_ADMIN_DINING_AREA_SEARCH_BUTTON_DISPLAYED';
export const RECENTER_ADMIN_DINING_TO_GEOLOCATION = 'admin_dining_guides.RECENTER_ADMIN_DINING_TO_GEOLOCATION';
export const SET_ADMIN_DINING_RECENTER_TO_MAP_MARKER = 'admin_dining_guides.SET_ADMIN_DINING_RECENTER_TO_MAP_MARKER';
export const SAVE_ADMIN_DINING_SEARCH_PAGE_STATE = 'admin_dining_guides.SAVE_ADMIN_DINING_SEARCH_PAGE_STATE';
export const HYDRATE_ADMIN_DINING_FROM_SEARCH_PAGE_SAVE_STATE =
	'admin_dining_guides.HYDRATE_ADMIN_DINING_FROM_SEARCH_PAGE_SAVE_STATE';
// export const CLEAR_SEARCH_PAGE_SAVE_STATE = 'CLEAR_SEARCH_PAGE_SAVE_STATE';
export const SET_ADMIN_DINING_CENTER_PAN = 'admin_dining_guides.SET_ADMIN_DINING_CENTER_PAN';
export const CLEAR_ADMIN_DINING_SEARCH_MAP = 'admin_dining_guides.CLEAR_ADMIN_DINING_SEARCH_MAP';
