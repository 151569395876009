export const ADD_BUSINESS_FILTER = 'admin_dining_guides.ADD_BUSINESS_FILTER';
export const APPLY_URL_SEARCH_FILTERS = 'admin_dining_guides.APPLY_URL_SEARCH_FILTERS';
export const CLEAR_FILTERS = 'admin_dining_guides.CLEAR_FILTERS';
export const CLEAR_SEARCH_PAGE_SAVE_STATE = 'admin_dining_guides.CLEAR_SEARCH_PAGE_SAVE_STATE';
export const FILTER_WORKING = 'admin_dining_guides.FILTER_WORKING';
export const HYDRATE_FROM_SEARCH_PAGE_SAVE_STATE = 'admin_dining_guides.HYDRATE_FROM_SEARCH_PAGE_SAVE_STATE';
export const REMOVE_BUSINESS_FILTER = 'admin_dining_guides.REMOVE_BUSINESS_FILTER';
export const RESET_SEARCH = 'admin_dining_guides.RESET_SEARCH';
export const SET_BUSINESS_SEARCH_RESULTS = 'admin_dining_guides.SET_BUSINESS_SEARCH_RESULTS';
export const SET_BUSINESS_SEARCH_FIND = 'admin_dining_guides.SET_BUSINESS_SEARCH_FIND';
export const SET_BUSINESS_SEARCH_NEAR = 'admin_dining_guides.SET_BUSINESS_SEARCH_NEAR';
export const SAVE_SEARCH_PAGE_STATE = 'admin_dining_guides.SAVE_SEARCH_PAGE_STATE';
